import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import store from './store';
import { LocalizationProvider } from './common/components/LocalizationProvider';
import ErrorHandler from './common/components/ErrorHandler';
import Navigation from './Navigation';
import preloadImages from './map/core/preloadImages';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import NativeInterface from './common/components/NativeInterface';
import ServerProvider from './ServerProvider';
import ErrorBoundary from './ErrorBoundary';
import AppThemeProvider from './AppThemeProvider';

preloadImages();

/*
const getManifest = async () => {
  const domain = (window.location.href).split('/')[2];
  return fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/manifest/${domain}/manifest.json`, { method: 'GET' })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      const content = encodeURIComponent(JSON.stringify(res));
      const url = `data:application/manifest+json,${content}`;
      const element = document.createElement('link');
      element.setAttribute('rel', 'manifest');
      element.setAttribute('href', url);
      document.querySelector('head').appendChild(element);
    })
    .catch((err) => {
      console.log(err);
    });
};

getManifest();
*/

const root = createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <LocalizationProvider>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <ServerProvider>
            <AppThemeProvider>
              <CssBaseline />
              <BrowserRouter>
                <Navigation />
              </BrowserRouter>
              <ErrorHandler />
              <NativeInterface />
            </AppThemeProvider>
          </ServerProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Provider>
  </ErrorBoundary>,
);

serviceWorkerRegistration.register();
