import React from 'react';
import { makeStyles } from '@mui/styles';
import VideoFundo from '../resources/images/fundo_login.mp4';

const useStyles = makeStyles(() => ({
  bgVideoWrap: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
    background: 'url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat center center/cover',
  },
  video: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    background: 'url(images/torre.jpg) no-repeat',
    backgroundSize: 'cover',
    zIndex: 1,
  },
  overlay: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: 'linear-gradient(45deg, rgba(0,0,0,.3) 50%, rgba(0,0,0,.7) 50%)',
    backgroundSize: '3px 3px',
    zIndex: 2,
  },
}));

const LoginVideo = () => {
  const classes = useStyles();

  return (
    <div className={classes.bgVideoWrap}>
      <video className={classes.video} src={VideoFundo} loop muted autoPlay />
      <div className={classes.overlay} />
    </div>

  );
};

export default LoginVideo;
