import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useTranslation } from '../common/components/LocalizationProvider';
import {
  formatDistance, formatHours, formatSpeed, formatVolume, formatTime,
} from '../common/util/formatter';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import {
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Container,
  Tooltip,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PublicIcon from '@mui/icons-material/Public';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import SpeedIcon from '@mui/icons-material/Speed';
import RouteIcon from '@mui/icons-material/Route';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { prefixString } from '../common/util/stringUtils';
import makeStyles from '@mui/styles/makeStyles';
import PositionValue from '../common/components/PositionValue';
import CarrierImage from './CarrierImage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '95vw',
  minHeight: '90vh',
  maxHeight: '90vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  padding: '20px',
  boxShadow: 24,
  borderRadius: '25px',
};

const useStyles = makeStyles((theme) => ({
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  dashboardLogo: {
    maxHeight: '120px',
    maxWidth: '200px',
    margin: '20px',
    marginTop: '25px',
    marginBottom: '35px',

  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  filterItem: {
    marginTop: '10px',
  },
  card: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px, rgba(0, 0, 0, 0.07) 0px 10px 10px',
    width: '100%',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center',
    minHeight: '300px',
    // backgroundColor: 'red',
  },
  cardHeader: {
    background: 'linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(0, 89, 178, 0.1) 120%)',
    // color: '#FFFFFF',
    width: '100%',
    // borderRadius: '20px',
    // padding: '8px',
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    // height: '50px',
  },
  over: {
    minWidth: '100%',
    maxHeight: '80vh',
    // backgroundColor: 'red',
  },
}));

const MultiReportModal = ({ openMRM, setOpenMRM, device, position }) => {
  const classes = useStyles();
  // const [revenda, setRevenda] = useState('');
  // alert(process.env.REACT_APP_MEDIA_SERVER + '/get/logs/' + seed +'' +isAdmin);
  const fetchReports = () => {
    // console.log(device);
  };
  const t = useTranslation();
  const navigate = useNavigate();
  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [periodVG, setPeriodVG] = useState('today');
  const [responseVG, setResponseVG] = useState(null);
  const [loadingVG, setLoadingVG] = useState(true);
  const [kmLitro, setKmLitro] = useState(8);
  const [precoLitro, setPrecoLitro] = useState(6.5);

  // const [expandedVG, setExpandedVG] = useState(false);

  const handleFilterVG = () => {
    setLoadingVG(true);

    fetch(`/api/reports/summary?from=${new Date(from).toISOString()}&to=${new Date(to).toISOString()}&daily=false&deviceId=${device.id}`, {
      "headers": {
        "accept": "application/json",
        "cache-control": "no-cache",
        "pragma": "no-cache"
      },
      "method": "GET",
      "credentials": "include"
    }).then((res) => res.json())
      .then((res) => {
        if (res.length >= 1) {
          setResponseVG(res);
        }
        setLoadingVG(false);
      });



    // setExpandedVG(!expandedVG);
    // console.log(new Date(from).toISOString(), new Date(to).toISOString());
  }

  useEffect(() => {
    handleChangeVG();
  }, [periodVG]);


  const formatValue = (item, key) => {
    try {
      switch (key) {
        case 'startTime':
          return formatTime(item[key], 'date', hours12);
        case 'startOdometer':
        case 'endOdometer':
        case 'distance':
          return formatDistance(item[key], distanceUnit, t);
        case 'averageSpeed':
        case 'maxSpeed':
          return formatSpeed(item[key], speedUnit, t);
        case 'engineHours':
          return formatHours(item[key]);
        case 'spentFuel':
          return formatVolume(item[key], volumeUnit, t);
        default:
          return item[key];
      }
    } catch {
      console.log('FormatValue Error...');
    }
  };

  const handleChangeVG = () => {
    console.log(position);
    let selectedFrom;
    let selectedTo;
    switch (periodVG) {
      case 'today':
        selectedFrom = moment().startOf('day');
        selectedTo = moment().endOf('day');
        break;
      case 'yesterday':
        selectedFrom = moment().subtract(1, 'day').startOf('day');
        selectedTo = moment().subtract(1, 'day').endOf('day');
        break;
      case 'thisWeek':
        selectedFrom = moment().startOf('week');
        selectedTo = moment().endOf('week');
        break;
      case 'previousWeek':
        selectedFrom = moment().subtract(1, 'week').startOf('week');
        selectedTo = moment().subtract(1, 'week').endOf('week');
        break;
      case 'thisMonth':
        selectedFrom = moment().startOf('month');
        selectedTo = moment().endOf('month');
        break;
      case 'previousMonth':
        selectedFrom = moment().subtract(1, 'month').startOf('month');
        selectedTo = moment().subtract(1, 'month').endOf('month');
        break;
      default:
        selectedFrom = moment(from, moment.HTML5_FMT.DATETIME_LOCAL);
        selectedTo = moment(to, moment.HTML5_FMT.DATETIME_LOCAL);
        break;
    }
    setFrom(selectedFrom.toISOString().replace('Z', '').split('.')[0]);
    setTo(selectedTo.toISOString().replace('Z', '').split('.')[0]);
  };


  useEffect(() => {
    if (openMRM === true) {
      setPeriodVG('today');
      handleChangeVG();
      handleFilterVG();

      try {
        if (JSON.parse(device.attributes.extraFields).kmLitro >= 1 && JSON.parse(device.attributes.extraFields).kmLitro >= 1) {
          setKmLitro(JSON.parse(device.attributes.extraFields).kmLitro);
          setPrecoLitro(JSON.parse(device.attributes.extraFields).precoLitro);
        } else {
          setKmLitro(7);
          setPrecoLitro(7.60);
        }
      } catch {
        setKmLitro(7);
        setPrecoLitro(7.60);
      }
    }
  }, [openMRM]);
  return (

    <div>
      <Modal
        open={openMRM}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ maxHeight: '100%' }}
      >
        <Box sx={style}>
          <div style={{
            minWidth: '350px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxHeight: '100%',
          }}
          >
            <Typography variant="h5" color="textSecondary">
              {device.name}
            </Typography>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '5px',
            }}
            >
              <IconButton
                size="small"
                onClick={() => { setOpenMRM(false); }}
              >
                <CloseIcon fontSize="small" className={classes.mediaButton} />
              </IconButton>
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '5px',
            overflowY: 'scroll',
            maxHeight: '100%',
            marginBottom: '50px',

          }}
          >
            <div className={classes.over}>
              <Grid container spacing={2}>
                <Grid item lg={4} sm={12} xs={12} md={6}>
                  <div className={classes.card}>
                    <Accordion className={classes.cardHeader}>
                      <AccordionSummary rotateIcon={false} expandIcon={<DateRangeIcon />}>
                        <Typography variant="subtitle1">
                          Visão Geral
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>

                        <div className={classes.filterItem}>
                          <FormControl fullWidth>
                            <InputLabel>{t('reportPeriod')}</InputLabel>
                            <Select label={t('reportPeriod')} value={periodVG} onChange={(e) => { setPeriodVG(e.target.value); }}>
                              <MenuItem value="today">{t('reportToday')}</MenuItem>
                              <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
                              <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
                              <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
                              <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
                              <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
                              <MenuItem value="custom">{t('reportCustom')}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className={classes.filterItem}>
                          <TextField
                            label={t('reportFrom')}
                            type="datetime-local"
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                            fullWidth
                            disabled={periodVG !== 'custom'}

                          />
                        </div>
                        <div className={classes.filterItem}>
                          <TextField
                            label={t('reportTo')}
                            type="datetime-local"
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                            fullWidth
                            disabled={periodVG !== 'custom'}
                          />
                        </div>
                        <div className={classes.filterItem}>
                          <Button onClick={() => { handleFilterVG(); }} variant="outlined" fullWidth>Filtrar</Button>
                        </div>

                      </AccordionDetails>
                    </Accordion>
                    {!responseVG && !loadingVG && (
                      <Typography style={{ marginTop: '50px', marginBottom: '50px' }} variant="caption" color="textSecondary">
                        Sem dados para o período
                      </Typography>
                    )}
                    {loadingVG ? (
                      <div style={{ width: '100%', minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress style={{ width: '50px', height: '50px' }} className={[classes.icones, classes.orange]} />
                      </div>
                    ) : (
                      <>
                        {responseVG && (
                          <>
                            <div style={{ marginTop: '30px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography variant="caption" color="textSecondary">
                                  Horímetro
                                </Typography>
                                <AccessAlarmIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                {responseVG !== null && (
                                  formatValue(responseVG[0], 'engineHours')
                                )}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography variant="caption" color="textSecondary">
                                  Vel. Média
                                </Typography>
                                <SpeedRoundedIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                {responseVG !== null && (
                                  formatValue(responseVG[0], 'averageSpeed')
                                )}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography variant="caption" color="textSecondary">
                                  Vel. Máx.
                                </Typography>
                                <SpeedIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                {responseVG !== null && (
                                  formatValue(responseVG[0], 'maxSpeed')
                                )}
                              </div>
                            </div>

                            <div style={{ marginTop: '30px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography variant="caption" color="textSecondary">
                                  Odôm. Início
                                </Typography>
                                <EditRoadIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                {responseVG !== null && (
                                  formatValue(responseVG[0], 'startOdometer')
                                )}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography variant="caption" color="textSecondary">
                                  Odôm. Final
                                </Typography>
                                <AddRoadIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                {responseVG !== null && (
                                  formatValue(responseVG[0], 'endOdometer')
                                )}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography variant="caption" color="textSecondary">
                                  Distância
                                </Typography>
                                <IconButton
                                  onClick={() => navigate('/replay')}
                                >
                                  <RouteIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                </IconButton>
                                {responseVG !== null && (
                                  formatValue(responseVG[0], 'distance') || 0
                                )}
                              </div>
                            </div>

                            <div style={{ marginBottom: '30px', marginTop: '30px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                              <Tooltip title="Você pode personalizar o consumo do seu veículo no cadastro de dispositivos.">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                  <Typography variant="caption" color="textSecondary">
                                    Gasto (Litros)
                                  </Typography>
                                  <LocalGasStationIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                  {responseVG !== null && (
                                    `${(responseVG[0].distance / 1000 / kmLitro).toFixed(2)} Lts`
                                  )}
                                </div>
                              </Tooltip>
                              <Tooltip title="Você pode informar o preço médio do litro no cadastro do veículo.">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                  <Typography variant="caption" color="textSecondary">
                                    Gasto (Dinheiro)
                                  </Typography>
                                  <AttachMoneyIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                  {responseVG !== null && (
                                    `R$ ${((responseVG[0].distance / 1000 / kmLitro) * precoLitro).toFixed(2)}`
                                  )}
                                </div>
                              </Tooltip>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography variant="caption" color="textSecondary">
                                  Protocolo
                                </Typography>
                                <PublicIcon style={{ fontSize: '40px', color: 'gray', marginBottom: '5px' }} />
                                {position && (
                                  position.protocol
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {/*
                  "distance": 38704,
                  "spentFuel": 0,

                  "averageSpeed": 17.78590623167849,
                  "maxSpeed": 47.6798,

                  "startOdometer": 9356236,
                  "endOdometer": 9394940,
                  
                  "startTime": "2023-07-19T06:05:36.000+00:00",
                  "endTime": "2023-07-19T19:50:33.000+00:00",
                  */}
                  </div>
                  <div style={{ height: '50px', color: 'white', opacity: '0.01' }}>Aqui</div>

                </Grid>
                <Grid item lg={8} sm={12} xs={12} md={6}>
                  <div className={classes.card}>
                    <Accordion className={classes.cardHeader} disabled>
                      <AccordionSummary rotateIcon={false} expandIcon={<DateRangeIcon />}>
                        <Typography variant="subtitle1">
                          Telemetria
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        filtros
                      </AccordionDetails>
                    </Accordion>
                    <div className={classes.content}>

                      <Paper style={{ width: '100%' }}>
                        <Table style={{ width: '100%' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('stateName')}</TableCell>
                              <TableCell>{t('sharedName')}</TableCell>
                              <TableCell>{t('stateValue')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <CarrierImage position={position} />
                            {position.network && Object.getOwnPropertyNames(position.network).filter((it) => it !== 'attributes').map((mnc) => (
                              <TableRow key={mnc}>
                                <TableCell>{mnc}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{mnc}</TableCell>
                              </TableRow>
                            ))}
                            {position && Object.getOwnPropertyNames(position).filter((it) => it !== 'attributes').map((property) => (
                              <TableRow key={property}>
                                <TableCell>{property}</TableCell>
                                <TableCell><strong>{t(prefixString('position', property))}</strong></TableCell>
                                <TableCell><PositionValue position={position} property={property} /></TableCell>
                              </TableRow>
                            ))}
                            {position && Object.getOwnPropertyNames(position.attributes).map((attribute) => (
                              <TableRow key={attribute}>
                                <TableCell>{attribute}</TableCell>
                                <TableCell><strong>{t(prefixString('position', attribute)) || t(prefixString('device', attribute))}</strong></TableCell>
                                <TableCell><PositionValue position={position} attribute={attribute} /></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                      <div style={{ height: '50px', color: 'white', opacity: '0.01' }}>Aqui</div>
                    </div>
                  </div>
                  <div style={{ height: '50px', color: 'white', opacity: '0.01' }}>Aqui</div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MultiReportModal;
