import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import QRCode from 'react-qr-code';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  CardMedia,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReplayIcon from '@mui/icons-material/Replay';
import RouteIcon from '@mui/icons-material/Route';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ShareIcon from '@mui/icons-material/Share';
// import PublishIcon from '@mui/icons-material/Publish';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingIcon from '@mui/icons-material/Pending';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from './LocalizationProvider';
import RemoveDialog from './RemoveDialog';
import PositionValue from './PositionValue';
import { useDeviceReadonly } from '../util/permissions';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { devicesActions } from '../../store';
import { useCatch, useCatchCallback } from '../../reactHelper';
import { useAttributePreference, usePreference } from '../util/preferences';
import {
  formatDistance, formatHours, formatSpeed, formatVolume, formatTime,
} from '../util/formatter';
import BasicModal from '../../main/BasicModal';
import BlockDialog from './BlockDialog';
import MultiReportModal from '../../main/MultiReportModal';
import CarrierImage from '../../main/CarrierImage';

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    overflowX: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    zIndex: 10,
    // borderBottomLeftRadius: '20px',
    [theme.breakpoints.up('md')]: {
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      boxShadow: '1px 2px 9px rgba(0, 0, 0, 0.9)',
      width: 'calc(100vw - 351px)',
      height: '160px',
      flexDirection: 'row',
      overflowY: 'hidden',
      overflowX: 'scroll',
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '40%',
      flexDirection: 'column',
      paddingLeft: '8px',
      paddingRight: '8px',
      overflowY: 'scroll',
    },
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'top',
    justifyContent: 'left',
    backgroundColor: 'white',
    height: '100%',

    [theme.breakpoints.up('md')]: {
      minWidth: '230px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  icones: {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
    },
  },
  shortcuts: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'left',
    backgroundColor: 'white',
    height: '100%',
    padding: '5px',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'row',
    },
  },

  draggable: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'left',
    backgroundColor: 'lightgray',
    opacity: '0.9',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minHeight: '45px',
      flexDirection: 'row',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
  },
  card: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    pointerEvents: 'auto',
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  orange: {
    color: '#FC6A03',
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-between',
  },
  /*
  root: ({ desktopPadding }) => ({
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: 5,
    // left: '50%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  }),
  */
}));

const CustomHeaderDetails = ({ icon, title }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(250, 250, 250)',
    height: '30px',
    padding: '8px',
  }}
  >
    {icon}
    <Typography variant="body1" color="textPrimary">
      {title}
    </Typography>
  </div>
);

const CustomStatusRow = ({ title, value, extra = '', icon = '' }) => (
  <>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '5px',
      paddingLeft: '5px',
      paddingRight: '5px',
    }}
    >
      <Typography variant="caption" color="textSecondary">
        {title}
        {icon}
      </Typography>

      <Typography style={{ maxWidth: '150px' }} noWrap variant="caption" color="textSecondary">
        {value}
        {extra}
      </Typography>
    </div>
    <Divider orientation="horizontal" flexItem />
  </>
);

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const positionAttributes = usePositionAttributes(t);
  const positionItems = useAttributePreference('positionItems', 'speed,address,totalDistance,course');

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [openMRM, setOpenMRM] = useState(false);

  const [days, setDays] = useState(1);
  const [u, setU] = useState('');
  const [l, setL] = useState('');

  const formatValue = (item, key) => {
    try {
      switch (key) {
        case 'startTime':
          return formatTime(item[key], 'date', hours12);
        case 'startOdometer':
        case 'endOdometer':
        case 'distance':
          return formatDistance(item[key], distanceUnit, t);
        case 'averageSpeed':
        case 'maxSpeed':
        case 'speed':
          return formatSpeed(item[key], speedUnit, t);
        case 'engineHours':
          return formatHours(item[key]);
        case 'spentFuel':
          return formatVolume(item[key], volumeUnit, t);
        default:
          return item[key];
      }
    } catch {
      console.log('FormatValue Error...');
    }
    return 1;
  };

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const handleShare = async () => {
    const futureDate = new Date(Date.now() + (3600 * 1000 * 24 * days)).toISOString();
    const tempPass = btoa(futureDate).toString('base64').toUpperCase();

    const payload = {
      id: -1,
      name: tempPass,
      email: tempPass,
      phone: '',
      readonly: true,
      // administrator: false,
      map: '',
      latitude: 0,
      longitude: 0,
      zoom: 0,
      password: tempPass,
      // twelveHourFormat: true,
      // coordinateFormat: '',
      disabled: false,
      expirationTime: futureDate,
      // deviceLimit: 1,
      // userLimit: 1,
      deviceReadonly: true,
      limitCommands: true,
      // fixedEmail: true,
      disableReports: true,
      poiLayer: '',
      attributes: { removeExpired: true },
    };

    const url = '/api/users';
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }).then((response) => response.json())
      .then((response) => {
        console.log('Response: ', response);
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(payload),
        }).then((response) => response.json())
          .then((response) => {
            response.map(async (x) => {
              if (x.name === tempPass) {
                // console.log(x.id, x.name);
                const payload = {
                  userId: x.id,
                  deviceId: device.id,
                };
                setU(tempPass);
                const url = '/api/permissions';
                const response = await fetch(url, {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(payload),
                });
                if (response.ok) {
                  console.log(await response);
                  setL(`https://${process.env.REACT_APP_URL_NAME}/login?specialAccess=${tempPass}`);
                  // setL(`http://192.168.0.242/login?specialAccess=${tempPass}`);
                } else {
                  throw Error(await response.text());
                }
                return 1;
              }
              return 1;
            });
            console.log('LISTA: ', response);
          });
      })
      .catch((err) => {
        alert('Erro ao compartilhar veículo, remova usuários antigos por favor');
        console.log(err);
      });
    // console.log(payload);
  };

  const handleBlockFlag = (position) => {
    let bloqueado;

    try {
      if (position.protocol === 'suntech' || position.protocol === 'teltonika' || position.protocol === 'gl200') {
        if (position.attributes.out1 === true || position.attributes.out1 === false) bloqueado = position.attributes.out1;
        if (position.attributes.output === 1 || position.attributes.output === 0) bloqueado = position.attributes.output;
      } else if (position.protocol === 'gps103') {
        if (position.attributes.event === 'jt') bloqueado = true;
        if (position.attributes.event === 'kt') bloqueado = false;
      } else {
        bloqueado = position.attributes.blocked;
      }
    } catch {
      bloqueado = false;
    }
    return bloqueado;
  };

  const handleBlockWait = (position) => {
    try {
      const lastSend = JSON.parse(localStorage.getItem(position.deviceId));
      const diff = (new Date().getTime() - new Date(lastSend.date).getTime()) / 1000;
      if (diff <= 25 || handleBlockFlag(position) === lastSend.lastStatus) {
        return true;
      }
      if (diff >= 180) {
        localStorage.removeItem(position.deviceId);
        return false;
      }
    } catch {
      // console.log('Nunca foi bloqueado...');
      return false;
    }
    return false;
  };

  const handleBlockResponse = (blocked) => {
    console.log(blocked);
    setBlocking(false);
  };

  const handleGeofence = useCatchCallback(async () => {
    const newItem = {
      name: '',
      area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
    // console.log(position);
  }, [navigate, position]);

  const cUser = useSelector((state) => state.session.user);
  const [extPermissions, setExtPermissions] = useState({
    userId: -1,
    all: true,
    mainMenu: true,
    preferences: true,
    notifications: true,
    account: true,
    devices: true,
    geofence: true,
    groups: true,
    drivers: true,
    calendars: true,
    calculatedAttr: true,
    maintenance: true,
    savedCommands: true,
    server: true,
    users: true,
    editExtPermissions: true,
    logs: true,
    billing: true,
    crm: true,
    easyconf: true,
    reports: true,
    about: true,
    blockDevice: true,
    moreOptions: true,
    replay: true,
    editDevice: true,
    removeDevice: true,
    shareDevice: true,
  });

  useEffect(() => {
    if (cUser !== null && extPermissions.userId === -9999) {
      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/permissions/${cUser.id}`, { method: 'GET' })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setExtPermissions(res);
        })
        .catch((err) => {
          console.log('Erro no get das permissões', err);
        });
    }
  }, [cUser]);

  return (
    <>
      {device && position && (
        <Draggable
          handle={`.${classes.draggable}, .${classes.header}`}
          onStop={onClose}
        >
          <div className={classes.statusContainer} style={{ '&::-webkit-scrollbar': { height: '4px' } }}>
            <div className={classes.draggable} style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Divider orientation="horizontal" flexItem />
            </div>
            <div className={classes.shortcuts} style={{ alignItems: 'center', justifyContent: 'center' }}>
              {extPermissions.blockDevice && (
                <div>
                  {!handleBlockWait(position) ? (
                    <IconButton
                      size="small"
                      disabled={disableActions || deviceReadonly}
                      onClick={() => setBlocking(true)}
                    >
                      {position && handleBlockFlag(position) ? (
                        <LockIcon className={[classes.icones, classes.negative]} />
                      ) : (
                        <LockOpenIcon className={[classes.icones, classes.positive]} />
                      )}
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      disabled={disableActions || deviceReadonly}
                    >
                      <CircularProgress style={{ width: '14px', height: '14px' }} className={[classes.icones, classes.orange]} />
                    </IconButton>
                  )}
                </div>
              )}
              {extPermissions.moreOptions && (
                <IconButton
                  color="secondary"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  disabled={!position}
                >
                  <PendingIcon className={classes.icones} />
                </IconButton>
              )}
              {extPermissions.replay && (
                <IconButton
                  onClick={() => navigate('/replay')}
                  disabled={disableActions || !position}
                >
                  <RouteIcon className={classes.icones} />
                </IconButton>
              )}
              {extPermissions.editDevice && (
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}`)}
                  disabled={disableActions || deviceReadonly}
                >
                  <EditIcon className={classes.icones} />
                </IconButton>
              )}
              {extPermissions.removeDevice && (
                <IconButton
                  onClick={() => setRemoving(true)}
                  disabled={disableActions || deviceReadonly}
                  className={classes.negative}
                >
                  <DeleteIcon className={classes.icones} />
                </IconButton>
              )}
            </div>
            {deviceImage && (
              <div className={classes.columns} style={{ alignItems: 'center', justifyContent: 'center' }}>
                <div style={{
                  backgroundImage: `url(/api/media/${device.uniqueId}/${deviceImage}?rand=aaabbbccc)`,
                  backgroundRepeat: 'no-repeat, no-repeat',
                  backgroundPosition: 'center, center',
                  backgroundSize: 'cover',
                  width: '100%',
                  height: '100%',
                  color: 'white',
                }}
                />
              </div>
            )}

            <div className={classes.columns}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'rgb(250, 250, 250)',
                height: '30px',
                padding: '8px',
              }}
              >
                <Typography variant="body1" style={{ fontSize: '17px', maxWidth: '250px' }} noWrap color="textPrimary">
                  {device.name}
                  
                </Typography>

                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                >

                  <div style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '25px',
                    marginRight: '5px',
                    backgroundColor: device.status === 'online' ? 'green' : device.status === 'offline' ? 'red' : '#ffa000',
                  }}
                  />

                  <Typography variant="body2" style={{ maxWidth: '50px' }} noWrap color="textSecondary">
                    {device.status === 'online' ? t('deviceStatusOnline') : device.status === 'offline' ? t('deviceStatusOffline') : t('deviceStatusUnknown')}
                  </Typography>
                </div>
              </div>

              {position && (
                <>
                  <CustomStatusRow title={t('positionAddress')} value={position.address} />
                  <CustomStatusRow title={t('deviceLastUpdate')} value={new Date(position.fixTime).toLocaleString()} />
                  <CustomStatusRow title={t('positionIgnition')} value={position.attributes.ignition ? t('sharedYes') : t('sharedNo')} />
                  <CustomStatusRow title={t('positionMotion')} value={position.attributes.motion ? t('sharedYes') : t('sharedNo')} />
                  <Divider orientation="vertical" flexItem />
                </>
              )}

            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.columns}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
              >
                <CustomHeaderDetails icon="" title={t('sharedAttributes')} />
                {extPermissions.dvr && (
                  <BasicModal d={device.attributes.dvrUrl ? 'block' : 'none'} icon={<PhotoCameraIcon style={{ opacity: '0.7', color: 'lightgray', fontSize: '20px' }} />} style={{ zIndex: 30 }}>
                    <iframe
                      frameBorder={0}
                      title="aqui"
                      src={device.attributes.dvrUrl}
                    />
                  </BasicModal>
                )}

                <MultiReportModal openMRM={openMRM} setOpenMRM={setOpenMRM} device={device} position={position} />

                <IconButton
                  size="small"
                  onClick={() => setOpenMRM(true)}
                >
                  <AssessmentIcon style={{ opacity: '0.7', color: 'gray', position: 'absolute', top: '5', fontSize: '20px' }} />
                </IconButton>

                {extPermissions.shareDevice && (
                  <BasicModal
                    d={disableActions || deviceReadonly ? 'none' : 'block'}
                    icon={<ShareIcon style={{ opacity: '0.7', color: 'gray', position: 'absolute', top: '5', fontSize: '20px' }} />}
                    style={{ zIndex: 30 }}
                  >
                    <Typography style={{ marginBottom: '20px' }} variant="body2" color="textSecondary">
                      Compartilhar Acesso a&nbsp;
                      <strong>{device.name}</strong>
                    </Typography>
                    <div style={{ display: 'flex', marginBottom: '20px', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
                      <TextField
                        style={{ maxWidth: '70px' }}
                        value={days || ''}
                        onChange={(event) => setDays(event.target.value)}
                        label="Dia(s)"
                      />
                      <IconButton
                        size="small"
                        onClick={() => handleShare()}
                      >
                        <ShareIcon style={{ opacity: '0.7', color: 'blue', fontSize: '19px' }} />
                      </IconButton>
                    </div>

                    <div style={{ display: 'flex', marginBottom: '20px', flexDirection: 'column', alignItems: 'center', justifyContent: 'left' }}>
                      {l && (
                        <QRCode
                          title="Recuperador"
                          value={l}
                          bgColor="white"
                          fgColor="black"
                          size={200}
                        />
                      )}

                      <TextField
                        style={{ marginBottom: '20px', marginTop: '20px', width: '350px' }}
                        value={l || ''}
                        label="Acesso Rápido"
                      />
                      <TextField
                        style={{ marginBottom: '20px', width: '350px', display: 'none' }}
                        value={u || ''}
                        label="Usuário"
                      />
                      <TextField
                        style={{ marginBottom: '20px', width: '350px', display: 'none' }}
                        value={u || ''}
                        label="Senha"
                      />
                    </div>
                  </BasicModal>
                )}
              </div>
              {position && (
                <div>
                  <CustomStatusRow icon={<CarrierImage position={position} mh={25} mw={22} />} title={t('positionRssi')} value={position.attributes.rssi} />
                  <CustomStatusRow title={t('positionBlocked')} value={handleBlockFlag(position) ? t('sharedYes') : t('sharedNo')} />
                  <CustomStatusRow title={t('positionCharge')} value={position.attributes.charge ? t('sharedYes') : t('sharedNo')} />
                  <CustomStatusRow title={t('positionProtocol')} value={position.protocol} />
                </div>
              )}
            </div>
            <div className={classes.columns}>
              <CustomHeaderDetails icon="" title="" />
              {position && (
                <div>
                  <CustomStatusRow extra={position.attributes.batteryLevel || position.attributes.battery >= 50 ? '%' : ' Volts'} title={t('positionBattery')} value={(position.attributes.batteryLevel || position.attributes.power || position.attributes.battery || position.attributes.voltage || parseFloat(position.attributes.io1) || parseFloat(position.attributes.io2) || 0).toFixed(0)} />
                  <CustomStatusRow title={t('positionSat')} value={position.attributes.sat} />
                  <CustomStatusRow extra="" title={t('positionSpeed')} value={formatValue(position, 'speed')} />
                  <CustomStatusRow title={t('positionValid')} value={position.valid ? t('sharedYes') : t('sharedNo')} />
                </div>
              )}
            </div>
            <div className={classes.columns}>
              <CustomHeaderDetails icon="" title="" />
              {position && (
                <div>
                  <CustomStatusRow extra={position.attributes.batteryLevel || position.attributes.battery >= 50 ? '%' : ' Volts'} title={t('positionBattery')} value={(position.attributes.batteryLevel || position.attributes.power || position.attributes.battery || position.attributes.voltage || parseFloat(position.attributes.io1) || parseFloat(position.attributes.io2) || 0).toFixed(0)} />
                  <CustomStatusRow title={t('positionSat')} value={position.attributes.sat} />
                  <CustomStatusRow extra="" title={t('positionSpeed')} value={formatValue(position, 'speed')} />
                  <CustomStatusRow title={t('positionValid')} value={position.valid ? t('sharedYes') : t('sharedNo')} />
                </div>
              )}
            </div>
            <div className={classes.columns}>
              <CustomHeaderDetails icon="" title="" />
              {position && (
                <div>
                  <CustomStatusRow extra={position.attributes.batteryLevel || position.attributes.battery >= 50 ? '%' : ' Volts'} title={t('positionBattery')} value={(position.attributes.batteryLevel || position.attributes.power || position.attributes.battery || position.attributes.voltage || parseFloat(position.attributes.io1) || parseFloat(position.attributes.io2) || 0).toFixed(0)} />
                  <CustomStatusRow title={t('positionSat')} value={position.attributes.sat} />
                  <CustomStatusRow extra="" title={t('positionSpeed')} value={formatValue(position, 'speed')} />
                  <CustomStatusRow title={t('positionValid')} value={position.valid ? t('sharedYes') : t('sharedNo')} />
                </div>
              )}
            </div>
          </div>
        </Draggable>
      )}

      <div className={classes.root} style={{ display: 'none' }}>
        {device && (
          <Draggable
            handle={`.${classes.media}, .${classes.header}`}
            onDrag={() => { alert(); }}
          >

            <Card elevation={3} className={classes.card}>
              card
              {deviceImage ? (
                <CardMedia
                  className={classes.media}
                  image={`/api/media/${device.uniqueId}/${deviceImage}`}
                >
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                  >
                    <CloseIcon fontSize="small" className={classes.mediaButton} />
                  </IconButton>
                </CardMedia>
              ) : (
                <div className={classes.header}>
                  <Typography variant="body2" color="textSecondary">
                    {device.name}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
              {position && (
                <CardContent className={classes.content}>
                  <Table size="small" classes={{ root: classes.table }}>
                    <TableBody>
                      {positionItems.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                        <StatusRow
                          key={key}
                          name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                          content={(
                            <PositionValue
                              position={position}
                              property={position.hasOwnProperty(key) ? key : null}
                              attribute={position.hasOwnProperty(key) ? null : key}
                            />
                          )}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              )}
              <CardActions classes={{ root: classes.actions }} disableSpacing>
                <IconButton
                  color="secondary"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  disabled={!position}
                >
                  <PendingIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigate('/replay')}
                  disabled={disableActions || !position}
                >
                  <ReplayIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}/command`)}
                  disabled={disableActions}
                >
                  <LockOpenIcon style={{ color: 'green' }} />
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}`)}
                  disabled={disableActions || deviceReadonly}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => setRemoving(true)}
                  disabled={disableActions || deviceReadonly}
                  className={classes.negative}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Draggable>
        )}
      </div>
      {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
          <MenuItem onClick={handleGeofence}>{t('sharedCreateGeofence')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}>{t('linkStreetView')}</MenuItem>
          <MenuItem component="button" href="#" onClick={() => { localStorage.removeItem(position.deviceId); }}>Limpar Comandos</MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
      <BlockDialog
        open={blocking}
        endpoint="commands"
        device={device}
        position={position}
        isBlocked={handleBlockFlag(position)}
        onResult={(blocked) => handleBlockResponse(blocked)}
      />
    </>
  );
};

export default StatusCard;
