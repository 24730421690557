import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import CachedIcon from '@mui/icons-material/Cached';
import { IconButton, Typography, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '350px',
  width: '450px',
  height: 'auto',
  // overflowY: 'scroll',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  padding: '20px',
  boxShadow: 24,
  borderRadius: '25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const useStyles = makeStyles((theme) => ({
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },

}));

const EasyConfModal = ({ openEasyConf, setOpenEasyConf }) => {
  const classes = useStyles();

  const [gateway, setGateway] = useState('');
  const [deviceModel, setDeviceModel] = useState('');
  const [commands, setCommands] = useState('');
  const [apn, setApn] = useState('');
  const [ip, setIp] = useState('');
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [trackers, setTrackers] = useState('');
  const [logs, setLogs] = useState('');

  const manufaturers = [
    {
      commands: `FACTORY#
SERVER,1,${ip},5023,0#
SERVER,0,${ip},5023,0#
APN,${apn},${user},${pass}#
TIMER,45,1800#
DORMANCY,45#
HBT,5,5#
SENDS,120#
STA_SENDGPS,1#
GMT,E,0,0#
ACCALM,ON,0#
POWERALM,ON,0,10,30#
BATALM,ON,0#
DIRREP,123456,0
SPEED,OFF#
MOVING,OFF#
DEFENSE,1#
SENALM,OFF# 
SENSORRANGE,2#
ALT,60#
MODE,1#
ACC,1#
Sensoign,2,2,5,180#`,
      model: ' HE-114 4G CAT-1',
      label: 'Hinova',
    },
    {
      commands: `FACTORY#
SERVER,1,${ip},5023,0#
SERVER,0,${ip},5023,0#
APN,${apn},${user},${pass}#
TIMER,45,1800#
HBT,5,5#
STATIC,86400#
ACCALM,ON,3,0#
BATALM,ON,0#
UTC,OFF#
GMT,W,0,0#
SENALM,ON,0#
ANGLEREP,ON#
SZCS#GT06METER=1
SZCS#GT06SEL=1#GT06IEXVOL=2
STATUS#
PARAM#
RESET#`,
      model: ' J14, J16, J16Pro',
      label: 'Chinês Genérico 4G CAT-1',
    },
    {
      commands: `AT+GTBSI=gv57,${apn},${user},${pass},,,,,FFFF$
AT+GTSRI=gv57,3,,1,${ip},5004,0.0.0.0,0,,0,0,0,1,,0,FFFF$
AT+GTCFG=gv57,gv57,gv57,1,10368.4,,,3F,0,,23D0F,,1,1,86400,0,,0,1,17,0,0,FFFF$
AT+GTDOG=gv57,1,60,7,0200,,1,0,,45,45,10,FFFF$
AT+GTPDS=gv57,1,238,,,,,,,FFFF$
AT+GTHRM=gv57,,,6F,4FE1FBF,FE17BF,F77D,EF,,,,,FFFF$
AT+GTFRI=gv57,1,0,,1,0000,0000,,30,1000,1000,,45,600,,,,,FFFF$
AT+GTTOW=gv57,1,5,2,60,0,0,0,0,2,10,4,,,,,,,,,FFFF$
AT+GTOUT=gv57,0,0,0,,,,1,,,,,,,FFFF$
AT+GTDIS=gv57,0,1,,0,,1,10,1,,,,,,,,,,,,,FFFF$
AT+GTEPS=gv57,2,250,250,2,2,0,0,0,0,1,0,0,10,FFFF$
AT+GTDIS=gv57,0,1,,0,,1,10,1,,,,,,,,,,,,,FFFF$
AT+GTGDO=gv57,0,30,1,,,,,,FFFF$
`,
      model: ' GV57',
      label: 'Queclink',
    },
    {
      commands: `ST300NTW;IMEI;02;0;${apn};${user};${pass};${ip};5011;;;;
ST300NTW;IMEI;02;1;${apn};${user};${pass};${ip};5011;;;;
ST300RPT;IMEI;02;600;60;1;0;600;0;0;0
ST300EVT;IMEI;02;0;30;0;12;13;9;10;20;0;1;7;1;1;0;0;0;0;0;0;9;9;0;0;0
ST300SVC;IMEI;02;0;300;0;0;0;0;1;0;1;1;0;0;0
ST300SVC;IMEI;02;0;300;2;0;0;0;1;0;1;1;0;0;0`,
      model: ' ST310U',
      label: 'Suntech',
    },
    {
      commands: `FACTORY#
GMT,W,0#
TIMER,30#      
STATIC,60#
HBT,1#
SETLOCX12#
SERVER,8520,${ip},5023#
APN,${apn},${user},${pass}#
SLPOFF#`,
      model: ' NT20, NT40',
      label: 'X3 Tech',
    },
    {
      commands: `FACTORY#
GMT,W,0#
APN,${apn},${user},${pass}#
GPRSON,1#
SERVER,1,${ip},5023,0#
TIMER,10,1500#
ADT,ON,1500#`,
      model: ' JM01, CRX1, CRX3, CRX7, GT100, GV20, JV200, JM007',
      label: 'concox',
    },
    {
      commands: `begin123456
time zone123456 0
apn123456 ${apn}
up123456 ${user} ${pass}
nosuppress123456
less gprs123456 on
adminip123456 ${ip} 5001
fix020s030m***n123456
heartbeat123456 60
tracker123456
gprs123456`,
      model: ' GPS102, GPS103, GPS104, GPS105, GPS106, GPS107, GPS303 ( TK303G )',
      label: 'coban',
    },
    {
      commands: `begin123456
apn123456 ${apn}
up123456 ${user} ${pass}
adminip123456 ${ip} 5001
t020s***n123456`,
      model: ' TK104, TK106',
      label: 'xexun1',
    },
    {
      commands: `begin123456
apn123456 ${apn}
up123456 ${user} ${pass}
adminip123456 ${ip} 5001
t020s***n123456`,
      model: ' TK103',
      label: 'xexun2',
    },
    {
      commands: `begin123456
apn123456 ${apn}
up123456 ${user} ${pass}
adminip123456 ${ip} 5006
t020s***n123456`,
      model: ' TK103-2, TK201, XT009, XT011',
      label: 'xexun3',
    },
    {
      commands: `#begin#
#timezone#123456#W#0#00#
#apn#123456#${apn}#
#ip#123456#${ip}#5023#
#tracker#123456#
#at#10#sun#0#`,
      model: ' GT-06 e Clones',
      label: 'Accurate',
    },
  ];

  const apns = [
    { carrier: 'Federal Sistemas Vivo', apn: 'smart.m2m.vivo.com.br', user: 'vivo', pass: 'vivo' },
    { carrier: 'Federal Sistemas Claro', apn: 'g.claro.com.br', user: 'claro', pass: 'claro' },
    { carrier: 'Transmeet Algar', apn: 'transmeet.br', user: 'transmeet', pass: 'transmeet' },
    { carrier: 'Transmeet Vivo', apn: 'transmeet.br', user: 'vivo', pass: 'vivo' },
    { carrier: 'Transmeet Claro', apn: 'transmeet.br', user: 'claro', pass: 'claro' },
    { carrier: 'Allcom Vivo', apn: 'allcom.vivo.com.br', user: 'allcom', pass: 'allcom' },
    { carrier: 'Allcom Claro', apn: 'allcom.claro.com.br', user: 'allcom', pass: 'allcom' },
    { carrier: 'Allcom Tim', apn: 'allcom.tim.com.br', user: 'allcom', pass: 'allcom' },
    { carrier: 'Allcom Algar', apn: 'allcom.algar.com.br', user: 'allcom', pass: 'allcom' },
    { carrier: 'Arqia - Multi', apn: 'iot4u.br', user: 'arqia', pass: 'arqia' },
    { carrier: 'VirtuEyes', apn: 'virtueyes.com.br', user: 'virtu', pass: 'virtu' },
  ];

  const handleChangeDevice = (event) => {
    let obj = manufaturers.find(o => o.label === event.target.value);
    setDeviceModel(obj.label);
  };

  const handleChangeApn = (event) => {
    let obj = apns.find(o => o.apn === event.target.value);
    setApn(obj.apn);
    setUser(obj.user);
    setPass(obj.pass);
  };

  const sendMessage = (to, message) => {
    //
    const _url = gateway.replace('{to}', to).replace('{message}', message).replace('#', '%23');

    return fetch(`${_url}`, {
      method: 'GET',
      mode: 'no-cors',
    })
      .then(response => response.text())
      .then(result => { return result })
      .catch(error => error);

  }

  const pause = (t) => {
    return new Promise(resolve => setTimeout(() => {
      resolve();
    }, t));
  }

  const handleCommands = async () => {

    const destinos = trackers.split('\n');

    let d = 0;
    while (d < destinos.length) {
      let comandos = commands.split('\n');
      let i = 0;
      while (i < comandos.length) {
        if (destinos[d] === '' || destinos[d].length < 10) {
          alert('Informe pelo menos 1 número de telefone');
          break;
        }
        await pause(25000);
        const response = await sendMessage(destinos[d], comandos[i]);
        let _ = logs + '\n' + destinos[d] + ' ' + comandos[i] + '\n';
        setLogs(_);
        console.log(destinos[d], comandos[i], i);
        i++;
      }
      await pause(6000);
      d++;
    }
  }

  useEffect(() => {
    setIp((window.location.href).split('/')[2]);
    setGateway('http://192.168.0.204:5554/SendSMS/user=&password=123456&to={to}&message={message}');
  }, [openEasyConf]);

  useEffect(() => {

    if (!deviceModel)
      return;
    // let _apn = apns.find(o => o.apn === apn);
    let _deviceModel = manufaturers.find(o => o.label === deviceModel);
    console.log(deviceModel);
    setCommands(_deviceModel.commands);
  }, [deviceModel, apn]);

  return (

    <div>
      <Modal
        open={openEasyConf}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              EasyConf <Typography variant="caption" color="textSecondary">Beta</Typography>
            </Typography>
            <IconButton
              size="small"
              onClick={() => { setOpenEasyConf(false); }}
            >
              <CloseIcon fontSize="small" className={classes.mediaButton} />
            </IconButton>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <TextField
                type="text"
                value={gateway}
                onChange={(e) => { setGateway(e.target.value) }}
                label="Gateway SMS"
              />
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-filled-label2">Operadora</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label2"
                id="demo-simple-select-filled2"
                value={apn}
                onChange={handleChangeApn}
                renderValue={v => v}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {apns?.map((option) => {
                  return (
                    <MenuItem key={option.apn} value={option.apn}>
                      {option.carrier}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-filled-label1">Aparelho</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label1"
                id="demo-simple-select-filled1"
                value={deviceModel}
                onChange={handleChangeDevice}
                renderValue={v => v}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {manufaturers?.map((option) => {
                  return (
                    <MenuItem key={option.label} value={option.label}>
                      {option.label},
                      {option.model}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <textarea
                style={{ height: '90px' }}
                value={commands}
                onChange={e => setCommands(e.target.value)}
              />
            </FormControl>

            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <textarea
                style={{ height: '90px' }}
                value={trackers}
                onChange={e => setTrackers(e.target.value)}
              />
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <textarea
                style={{ height: '40px' }}
                disabled
                value={logs}
                onChange={e => setLogs(e.target.value)}
              />
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <Button onClick={handleCommands}>Enviar</Button>
            </FormControl>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EasyConfModal;
