import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '10%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  padding: '20px',
  boxShadow: 24,
  borderRadius: '25px',
};

const FirstTimeAccess = ({ children, openFTA, setOpenFTA }) => {

  return (
    <div>
      <Modal
        open={openFTA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
};

export default FirstTimeAccess;
