import React from 'react';
import Button from '@mui/material/Button';
import { Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { useTranslation } from './LocalizationProvider';
// import { useCatch } from '../../reactHelper';
import { snackBarDurationLongMs } from '../util/duration';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(1)})`,
    },
  },
  buttonRed: {
    height: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.colors.negative,
  },
  buttonGreen: {
    height: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.colors.positive,
  },
}));

const BlockDialog = ({
  open, onResult, position, isBlocked, device,
}) => {
  const classes = useStyles();
  // const t = useTranslation();

  const handleBlock = async () => {
    const url = '/api/commands/send';

    // console.log(position);

    const lastSend = {
      date: new Date(),
      lastStatus: isBlocked,
    };

    if (localStorage.getItem(position.deviceId) === null) {
      localStorage.setItem(position.deviceId, JSON.stringify(lastSend));
    } else {
      // console.log(lastSend);
      const diff = (new Date().getTime() - new Date(JSON.parse(localStorage.getItem(position.deviceId)).date).getTime()) / 1000;
      if (diff <= 45) {
        // alert('Aguarde o retorno do rastreador por favor...');
        onResult(true);
        return;
      }
      localStorage.setItem(position.deviceId, JSON.stringify(lastSend));
    }

    if (typeof device.attributes.customCommands !== 'undefined') {
      // console.log(JSON.parse(device.attributes.customCommands));

      const c = JSON.parse(device.attributes.customCommands);

      const customCommand = {
        id: 0,
        deviceId: device.id,
        description: '',
        type: 'custom',
        textChannel: false,
        attributes: {
          data: isBlocked ? c.engineResume : c.engineStop,
        },
      };
      const customResponse = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(customCommand),
      });
      if (customResponse.ok) {
        console.log(await customResponse.json());
        onResult(true);
      } else {
        throw Error(await customResponse.text());
      }
    } else {
      const command = {
        id: 0,
        deviceId: device.id,
        description: '',
        type: isBlocked ? 'engineResume' : 'engineStop',
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(command),
      });
      if (response.ok) {
        console.log(await response.json());
        onResult(true);
      } else {
        throw Error(await response.text());
      }
    }

    /*
        if (position.protocol === 'teltonika') {
          const commandStopTeltonika = 'setdigout 1';
          const commandResumeTeltonika = 'setdigout 0';
          const commandTeltonica = {
            id: 0,
            deviceId: device.id,
            description: '',
            type: 'custom',
            textChannel: false,
            attributes: {
              data: isBlocked ? commandResumeTeltonika : commandStopTeltonika,
            },
          };
          const responseTeltonika = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(commandTeltonica),
          });
          if (responseTeltonika.ok) {
            console.log(await responseTeltonika);
            onResult(true);
          } else {
            throw Error(await responseTeltonika.text());
          }
        } else if (position.protocol === 'gt06') {

          const commandStopGt06 = 'DYD';
          const commandResumeGt06 = 'HFYD';
          const commandGt06 = {
            id: 0,
            deviceId,
            description: '',
            type: 'custom',
            textChannel: false,
            attributes: {
              data: isBlocked ? commandResumeGt06 : commandStopGt06,
            },
          };
          const responseGt06 = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(commandGt06),
          });
          if (responseGt06.ok) {
            console.log(await responseGt06);
            // onResult(true);
          } else {
            throw Error(await responseGt06.text());
          }
        } else {
          const commandStop = 'engineStop';
          const commandResume = 'engineResume';
          const command = {
            id: 0,
            deviceId,
            description: '',
            type: isBlocked ? commandResume : commandStop,
          };
          const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(command),
          });
          if (response.ok) {
            console.log(await response);
            onResult(true);
          } else {
            throw Error(await response.text());
          }
        }
        */
  };

  return (
    <Snackbar
      className={classes.root}
      open={open}
      autoHideDuration={snackBarDurationLongMs}
      onClose={() => onResult(false)}
      message={(
        <div style={{ width: '100%' }}>
          Deseja realmente
          <Button style={{ position: 'relative', left: '2px' }} size="small" className={isBlocked ? classes.buttonGreen : classes.buttonRed} onClick={handleBlock}>
            {isBlocked ? 'Desbloquear?' : 'Bloquear?'}
          </Button>
        </div>
      )}

    />
  );
};

export default BlockDialog;
