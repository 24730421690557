import React, { useState, useEffect } from 'react';
import {
  Divider, List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreateIcon from '@mui/icons-material/Create';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import PublishIcon from '@mui/icons-material/Publish';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  useAdministrator, useManager, useRestriction,
} from '../../common/util/permissions';
import useFeatures from '../../common/util/useFeatures';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const readonly = useRestriction('readonly');
  const admin = useAdministrator();
  const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);
  // const extPermissions = useSelector((state) => state.session.permissions);

  const user = useSelector((state) => state.session.user);

  const [extPermissions, setExtPermissions] = useState({
    userId: -1,
    all: true,
    mainMenu: true,
    preferences: true,
    notifications: true,
    account: true,
    devices: true,
    geofence: true,
    groups: true,
    drivers: true,
    calendars: true,
    calculatedAttr: true,
    maintenance: true,
    savedCommands: true,
    server: true,
    users: true,
    editExtPermissions: true,
    logs: true,
    billing: true,
    crm: true,
    easyconf: true,
    reports: true,
    about: true,
    blockDevice: true,
    moreOptions: true,
    replay: true,
    editDevice: true,
    removeDevice: true,
    shareDevice: true,
  });

  useEffect(() => {
    console.log(userId);
    console.log(user);
    if (userId !== null && extPermissions.userId === -9999) {
      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/permissions/${userId}`, { method: 'GET' })
        .then((res) => res.json())
        .then((res) => {
          console.log('SSSSSSSSS', res, userId, user);
          // console.log(userId);
          // console.log(user);
          setExtPermissions(res);
        })
        .catch((err) => {
          console.log('Erro no get das permissões', err);
        });
    }
  }, [userId]);

  const features = useFeatures();
  // console.log('WWWWWW', extPermissions);
  return (
    <>
      <List>
        <MenuItem
          title="Monitoramento"
          link="/?"
          icon={<LocationOnIcon />}
        />
        {extPermissions.preferences && (
          <MenuItem
            title={t('sharedPreferences')}
            link="/settings/preferences"
            icon={<SettingsIcon />}
            selected={location.pathname === '/settings/preferences'}
          />
        )}
        {!readonly && (
          <>
            {extPermissions.notifications && (
              <MenuItem
                title={t('sharedNotifications')}
                link="/settings/notifications"
                icon={<NotificationsIcon />}
                selected={location.pathname.startsWith('/settings/notification')}
              />
            )}
            {extPermissions.account && (
              <MenuItem
                title={t('settingsUser')}
                link={`/settings/user/${userId}`}
                icon={<PersonIcon />}
                selected={location.pathname === `/settings/user/${userId}`}
              />
            )}
            {extPermissions.devices && (
              <MenuItem
                title={t('deviceTitle')}
                link="/settings/devices"
                icon={<SmartphoneIcon />}
                selected={location.pathname.startsWith('/settings/device')}
              />
            )}
            {extPermissions.geofence && (
              <MenuItem
                title={t('sharedGeofences')}
                link="/geofences"
                icon={<CreateIcon />}
                selected={location.pathname.startsWith('/settings/geofence')}
              />
            )}
            {!features.disableGroups && extPermissions.groups && (
              <MenuItem
                title={t('settingsGroups')}
                link="/settings/groups"
                icon={<FolderIcon />}
                selected={location.pathname.startsWith('/settings/group')}
              />
            )}
            {!features.disableDrivers && extPermissions.drivers && (
              <MenuItem
                title={t('sharedDrivers')}
                link="/settings/drivers"
                icon={<PersonIcon />}
                selected={location.pathname.startsWith('/settings/driver')}
              />
            )}
            {!features.disableCalendars && extPermissions.calendars && (
              <MenuItem
                title={t('sharedCalendars')}
                link="/settings/calendars"
                icon={<TodayIcon />}
                selected={location.pathname.startsWith('/settings/calendar')}
              />
            )}
            {!features.disableComputedAttributes && extPermissions.calculatedAttr && (
              <MenuItem
                title={t('sharedComputedAttributes')}
                link="/settings/attributes"
                icon={<StorageIcon />}
                selected={location.pathname.startsWith('/settings/attribute')}
              />
            )}
            {!features.disableMaintenance && extPermissions.maintenance && (
              <MenuItem
                title={t('sharedMaintenance')}
                link="/settings/maintenances"
                icon={<BuildIcon />}
                selected={location.pathname.startsWith('/settings/maintenance')}
              />
            )}
            {extPermissions.savedCommands && (
              <MenuItem
                title={t('sharedSavedCommands')}
                link="/settings/commands"
                icon={<PublishIcon />}
                selected={location.pathname.startsWith('/settings/command')}
              />
            )}
          </>
        )}
      </List>
      {manager && (
        <>
          <Divider />
          <List>
            {admin && extPermissions.server && (
              <MenuItem
                title={t('settingsServer')}
                link="/settings/server"
                icon={<StorageIcon />}
                selected={location.pathname === '/settings/server'}
              />
            )}
            {extPermissions.users && (
              <MenuItem
                title={t('settingsUsers')}
                link="/settings/users"
                icon={<PeopleIcon />}
                selected={location.pathname.startsWith('/settings/user') && location.pathname !== `/settings/user/${userId}`}
              />
            )}
          </List>
        </>
      )}
    </>
  );
};

export default SettingsMenu;
