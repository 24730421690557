import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FormControl, InputLabel, Select, MenuItem, Table, TableHead, TableRow, TableBody, TableCell, Button, Container, CircularProgress, Typography, TextField,
} from '@mui/material';
import {
  formatDistance, formatHours, formatSpeed, formatVolume, formatTime,
} from '../../common/util/formatter';
import ReportFilter from '../components/ReportFilter';
import { useAttributePreference, usePreference } from '../../common/util/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';
import PageLayout from '../../common/components/PageLayout';
import ReportsMenu from '../components/ReportsMenu';
import usePersistedState from '../../common/util/usePersistedState';
import ColumnSelect from '../components/ColumnSelect';
import { useCatch } from '../../reactHelper';
import useReportStyles from '../common/useReportStyles';
import TableShimmer from '../../common/components/TableShimmer';
import scheduleReport from '../common/scheduleReport';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import CheckboxListSecondary from '../components/CheckListSecondary';

const columnsArray = [
  ['startTime', 'reportStartDate'],
  ['distance', 'sharedDistance'],
  ['startOdometer', 'reportStartOdometer'],
  ['endOdometer', 'reportEndOdometer'],
  ['averageSpeed', 'reportAverageSpeed'],
  ['maxSpeed', 'reportMaximumSpeed'],
  ['engineHours', 'reportEngineHours'],
  ['spentFuel', 'reportSpentFuel'],
];
const columnsMap = new Map(columnsArray);

const DevicesSpeed = () => {
  const navigate = useNavigate();
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  // const [columns, setColumns] = usePersistedState('DevicesSummary', ['name', 'distance', 'averageSpeed']);

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([])
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [company, setCompany] = useState(null);
  const [timeStart, setTimeStart] = useState(0);
  const [timeEnd, setTimeEnd] = useState(31536000)

  const formatValue = (item, key) => {
    switch (key) {
      case 'deviceId':
        return devices[item[key]].name;
      case 'startTime':
        return formatTime(item[key], 'date', hours12);
      case 'startOdometer':
      case 'endOdometer':
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'averageSpeed':
      case 'maxSpeed':
      case 'speed':
      case 'speedLimit':
        return formatSpeed(item[key] || 0, speedUnit, t);
      case 'engineHours':
        return formatHours(item[key]);
      case 'spentFuel':
        return formatVolume(item[key], volumeUnit, t);
      default:
        return item[key];
    }
  };

  useEffect(() => {

    const url = (window.location.href).split('/')[2];

    fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/domain/${url}`, {
      method: 'GET',
    }).then((response) => response.json())
      .then((response) => {
        setCompany(response);
        // console.log(response);
        fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/${response.urlLogotipo}`)
          .then(response => response.blob())
          .then(blob => {
            var reader = new FileReader();
            reader.onload = function () { setImg(this.result); }; // <--- `this.result` contains a base64 data URI
            reader.readAsDataURL(blob);
          });

      });

    setTimeStart('Todos');
    setTimeEnd('Todos');

    fetch('/api/devices', {
      method: 'GET'
    }).then((res) => res.json())
      .then((res) => {
        setItems(res);
        // console.log(res);
      })

  }, []);

  useEffect(() => {

    let temp = [];
    if (search.length < 2) {
      temp = [];
      setFilteredItems([]);
      return;
    }

    items.map(item => {
      if (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        temp.push(item);
      }
    });

    setFilteredItems(temp);

    // setFilteredItems(items.find(element => element.name.includes(search)));
    // console.log(temp, search, items);
  }, [search]);

  useEffect(() => {
    console.log(selectedItems);
  }, [selectedItems]);


  const getRelations = async (userId, endpoint) => {
    return await fetch(`/api/${endpoint}?userId=${userId}`, {
      "headers": {
        "accept": "application/json",
        "cache-control": "no-cache",
        "pragma": "no-cache"
      },
      "method": "GET",
      "credentials": "include"
    }).then((res) => res.json())
      .then((res) => {
        return res;
      });
  }

  const getLastPosition = async (deviceId, endpoint) => {
    return await fetch(`/api/${endpoint}?deviceId=${deviceId}`, {
      "headers": {
        "accept": "application/json",
        "cache-control": "no-cache",
        "pragma": "no-cache"
      },
      "method": "GET",
      "credentials": "include"
    }).then((res) => res.json())
      .then((res) => {
        return res;
      });
  }

  const getReplay = async (deviceId, endpoint, from = '2023-07-31T03%3A00%3A00.000Z', to = '2023-08-01T02%3A59%3A59.999Z') => {
    return await fetch(`/api/${endpoint}?deviceId=${deviceId}&from=${from}&to=${to}`, {
      "headers": {
        "accept": "application/json",
        "cache-control": "no-cache",
        "pragma": "no-cache"
      },
      "method": "GET",
      "credentials": "include"
    }).then((res) => res.json())
      .then((res) => {
        return res;
      });
  }

  const filterDates = (seed, start, end) => {
    if (seed === null && start === 'NR') {
      return true;
    }

    if (start === 'Todos') {
      return true;
    }

    if (seed === null) {
      return false;
    }

    const millisec = new Date() - new Date(seed);
    var seconds = (millisec / 1000).toFixed(0);

    if (seconds > start && seconds < end) {
      return true;
    }

    return false;
  }

  const diffDates = (seed) => {
    if (seed === null) {
      return { time: "NR", color: 'red' };
    }
    // const pad = function(n){ return n < 10 ? '0' + n : n; };
    const millisec = new Date() - new Date(seed);
    var seconds = (millisec / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = "";
    var color = "";

    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = (hours >= 10) ? hours : "0" + hours;
      minutes = minutes - (hours * 60);
      minutes = (minutes >= 10) ? minutes : "0" + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : "0" + seconds;
    if (hours >= 24) {
      color = "purple";
    } else if (hours >= 12) {
      color = "darkred";
    } else if (hours >= 6) {
      color = "red";
    } else if (hours >= 3) {
      color = "#ff5c33";
    } else if (minutes >= 30 || hours >= 1) {
      color = "#FFA000";
    } else {
      color = "green";
    }

    if (hours != "") {
      if (hours >= 24)
        return { time: Math.floor(hours / 24) + ' D(s)', color: color };
      return { time: hours + ":" + minutes + ":" + seconds, color: color };
    }
    return { time: minutes + ":" + seconds, color: color };
  }

  const checkSpeed = (object, position) => {

    try {
      if (object.attributes?.speedLimit < position.speed) {
        return { time: 1 + ":" + 2, color: "#fcdbd9" };
      } else if (object.attributes?.speedLimit > position.speed) {
        return { time: 1 + ":" + 2, color: "#e3fcd9" };
      }
    } catch {
    }
    return { time: 1 + ":" + 2, color: "lightgray" };
    
    /* 
        return;
        // const speed
    
        if (hours >= 24) {
          color = "purple";
        } else if (hours >= 12) {
          color = "darkred";
        } else if (hours >= 6) {
          color = "red";
        } else if (hours >= 3) {
          color = "#ff5c33";
        } else if (minutes >= 30 || hours >= 1) {
          // color = "#FFA000"; // orange
          color = "green";
        } else {
        }
    
        if (hours != "") {
          if (hours >= 24)
            return { time: Math.floor(hours / 24) + ' D(s)', color: color };
          return { time: hours + ":" + minutes + ":" + seconds, color: color };
        }
        return { time: minutes + ":" + seconds, color: color };
    */
  }

  const getObjects = async () => {
    let url = '?';
    selectedItems.map(ii => {
      url += `id=${ii}&`
    })
    console.log(url);
    return await fetch(`/api/devices${url}`, {
      "headers": {
        "accept": "application/json",
        "cache-control": "no-cache",
        "pragma": "no-cache"
      },
      "method": "GET",
      "credentials": "include"
    }).then((res) => res.json())
      .then((res) => {
        // console.log(res);
        return res;
      });
  }

  const createPDF = async (object, positionsList) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let fallback = {
      placa: "N/D",
      corPredominante: "N/D",
      marcaModelo: "N/D",
      especieTipo: "N/D",
      anoFabMod: "N/D",
      combustível: "N/D",
      categoria: "N/D",
      potenciacilidrada: "N/D",
      carroceria: "N/D",
      numMotor: "N/D",
      chassi: "N/D",
      placaAnterior: "N/D",
      observacoes: "N/D",
      cidade: "N/D",
      uf: "N/D",
      precoLitro: "N/D",
      kmLitro: "N/D"
    };

    try {
      fallback = {
        ...fallback,
        placa: JSON.parse(object?.attributes?.extraFields).placa,
        corPredominante: JSON.parse(object?.attributes?.extraFields).corPredominante,
        marcaModelo: JSON.parse(object?.attributes?.extraFields).marcaModelo,
        especieTipo: JSON.parse(object?.attributes?.extraFields).especieTipo,
        anoFabMod: JSON.parse(object?.attributes?.extraFields).anoFabMod,
        combustível: JSON.parse(object?.attributes?.extraFields).combustível,
        categoria: JSON.parse(object?.attributes?.extraFields).categoria,
        potenciacilidrada: JSON.parse(object?.attributes?.extraFields).potenciacilidrada,
        carroceria: JSON.parse(object?.attributes?.extraFields).carroceria,
        numMotor: JSON.parse(object?.attributes?.extraFields).numMotor,
        chassi: JSON.parse(object?.attributes?.extraFields).chassi,
        placaAnterior: JSON.parse(object?.attributes?.extraFields).placaAnterior,
        observacoes: JSON.parse(object?.attributes?.extraFields).observacoes,
        cidade: JSON.parse(object?.attributes?.extraFields).cidade,
        uf: JSON.parse(object?.attributes?.extraFields).uf,
        precoLitro: JSON.parse(object?.attributes?.extraFields).precoLitro,
        kmLitro: JSON.parse(object?.attributes?.extraFields).kmLitro,
      }

    } catch {

    }

    const reportTitle = [
      {
        // style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto'],
          heights: [100],
          body: [
            [
              {
                border: [false, false, false, false],
                // fillColor: '#dddddd',
                image: img,
                width: 120,
                opacity: 0.7
              },
              {
                border: [false, false, false, false],
                // fillColor: '#dddddd',
                color: '#292929',
                text: [
                  { text: "Relatório Velocidade", fontSize: 15 },
                  { text: `\n${object.name} - ${fallback.placa} - ${fallback.marcaModelo}`, fontSize: 8 },
                  { text: `\n${new Date().toLocaleString('pt-BR')}`, fontSize: 9, margin: [0, 10, 0, 20] },
                  /* 
                  placa
                  corPredominante
                  marcaModelo
                  especieTipo
                  anoFabMod
                  combustível
                  categoria
                  potenciacilidrada
                  carroceria
                  numMotor
                  chassi
                  placaAnterior
                  observacoes
                  cidade
                  uf
                  precoLitro
                  kmLitro
                  */
                  // { text: `\nVocê tem ${userList.length} clientes cadastrados`, fontSize: 9, margin: [0, 20, 0, 10] },
                ],
                alignment: 'center',
                margin: [0, 10, 0, 0],
              },
              {
                border: [false, false, false, false],
                // fillColor: '#c0c0c0',
                color: '#c0c0c0',
                text: [
                  { text: company.nomeFantasia.concat("\n"), italics: true, fontSize: 13 },
                  { text: company.email.concat("\n"), fontSize: 11 },
                  { text: company.contato.concat("\n"), fontSize: 11 },
                  { text: `https://${(window.location.href).split('/')[2]}\n`, fontSize: 11 },
                  // { text: `${company.email}\n` },
                  // { text: `${company.contato}\n` },
                ],
                // tesxt: `${company.nomeFantasia}\nhttps://${(window.location.href).split('/')[2]}\n${company.email}\n${company.contato}`,
                alignment: 'right',
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
        margin: [25, 15, 25, 15],
      },
    ];

    // const dados = await getUsers();
    // console.log(userList);

    const x = await Promise.all(
      /* 
            var sources = images.filter(function(img) {
              if (img.src.split('.').pop() === "json") {
                return false; // skip
              }
              return true;
            }).map(function(img) { return img.src; });
      */


      positionsList.map(async (position) => {
        return [
          { text: 'Servidor: ' + new Date(position.serverTime).toLocaleString('pt-BR') + '\n FixTime: ' + new Date(position.fixTime).toLocaleString('pt-BR') + '\nRastreador: ' + new Date(position.deviceTime).toLocaleString('pt-BR'), fillColor: checkSpeed(object, position).color, borderColor: [checkSpeed(object, position).color, '', '', ''], fontSize: 8, margin: [0, 2, 0, 2], border: [true, false, false, false] },
          { text: formatValue(object.attributes, 'speedLimit') || '0', fillColor: checkSpeed(object, position).color, fontSize: 8, opcacity: 0.2, margin: [0, 2, 0, 2], border: [false, false, false, false], alignment: 'right' },
          { text: formatValue(position, 'speed'), fillColor: checkSpeed(object, position).color, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
          { text: position.address, fontSize: 8, fillColor: checkSpeed(object, position).color, margin: [0, 2, 0, 2], border: [false, false, false, false] },
          { text: (position.latitude || 0).toFixed(6), fillColor: checkSpeed(object, position).color, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false], alignment: 'center' },
          { text: (position.longitude || 0).toFixed(6), fillColor: checkSpeed(object, position).color, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false], alignment: 'right' },
        ];
      })
    );

    const details = [
      {
        text: [
          { text: `\n${x.length} objetos encontrados com esse estado`, fontSize: 9 },
        ],
        margin: [0, 2, 0, 10],
      },
      {
        table: {
          widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto'],
          headerRows: 1,
          body: [
            [
              { text: 'Datas', fontSize: 9, border: [false, false, false, true], alignment: 'left' },
              { text: 'Max Perm.', fontSize: 9, border: [false, false, false, true], alignment: 'left' },
              { text: 'Velocidade', noWrap: true, fontSize: 9, border: [false, false, false, true], alignment: 'left' },
              { text: 'Endereço', fontSize: 9, border: [false, false, false, true], alignment: 'left' },
              { text: 'Latitude', fontSize: 9, border: [false, false, false, true], alignment: 'left' },
              { text: 'Longitude', fontSize: 9, border: [false, false, false, true], alignment: 'left' },
            ],
            ...x,

          ]
        },
        // B7E9F7
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex % 2 === 0) ? '#D8F9FF' : null;
          },
        }
      }
    ];

    const Footer = (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} / ${pageCount}`,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0],
        }
      ];
    };

    const docDefinitions = {
      pageSize: 'A4',
      pageMargins: [15, 80, 15, 40], // 
      pageOrientation: 'landscape',

      header: [reportTitle],
      content: [details],
      footer: Footer,
    };

    pdfMake.createPdf(docDefinitions).download('file.pdf', function () { handleFinish(); });
  }

  const handleFinish = () => {
    return;
    setLoading(false);
    // history.go(-1);
  }

  const handleReport = async () => {


    const objectList = await getObjects();

    objectList.map(async (object) => {
      setLoading(true);
      const positionsList = await getReplay(object.id, 'positions');
      console.log(object, positionsList);
      createPDF(object, positionsList);
      setLoading(false);
    });

    // setLoading(false);

    return;


    // mudar a porra da abordagem... aqui fazer um map, passando os dados do veículo e as linhas já são as das positiions....


    // objectList.map(async (object) => {
    // const devices = await getRelations(user.id, 'devices');
    // const groups = await getRelations(user.id, 'groups');
    // const geofences = await getRelations(user.id, 'geofences');
    // const notifications = await getRelations(user.id, 'notifications');
    // const calendars = await getRelations(user.id, 'calendars');
    // const users = await getRelations(user.id, 'users');
    // const computed = await getRelations(user.id, 'attributes/computed');
    // const drivers = await getRelations(user.id, 'drivers');
    // const commands = await getRelations(user.id, 'commands');
    // const maintenance = await getRelations(user.id, 'maintenance');
    // console.log(user.id, user.name, devices, groups, geofences, notifications, calendars, users, computed, drivers, commands, maintenance);
    // console.log(user.id, user.name, devices.length, groups.length, geofences.length, notifications.length, calendars.length, users.length, computed.length, drivers.length, commands.length, maintenance.length);
    // console.log(user.id, user.name, devices.length);
    // console.log(object.lastUpdate);
    // });

    // `${process.env.REACT_APP_MEDIA_SERVER}/get/${response.urlLogotipo}`

    createPDF(objectList);
    // history.go(-1);

  }

  return (
    <div style={{ flexDirection: 'column', display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
      {img !== null && <img crossOrigin="anonymous" id="baseLogo" onLoad={(event) => event.target.style.display = 'inline-block'} onError={(event) => event.target.style.display = 'none'} style={{ maxHeight: '60px', position: 'fixed', top: '10%' }} src={img} alt="Uploaded Image" />}
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress style={{ opacity: 0.7, color: 'lightblue', margin: '20px', width: '80px', height: '80px' }} />
          <Typography variant="caption" color="textSecondary" style={{ marginTop: '5px', fontSize: '13px', maxWidth: '80%' }}>
            Gerando Relatório de Velocidade, por favor aguarde...
          </Typography>
        </div>
      ) : (
        <>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: '5px', fontSize: '17px', maxWidth: '80%' }}>
            Relatório Velocidade
          </Typography>
          <Typography variant="caption" color="textSecondary" style={{ marginTop: '5px', fontSize: '13px', maxWidth: '80%' }}>
            Pode demorar de 2 a 5 minutos para gerar os seus relatórios, conforme o número de registros.
          </Typography>
          <div style={{ width: '85%', maxWidth: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '30px' }}>
            <TextField value={search} label="Pesquisar" onChange={(e) => setSearch(e.target.value)} fullWidth />
          </div>
          <div style={{ maxWidth: '300px', height: '200px', overflowY: 'scroll', display: 'flex', alignItems: 'top', justifyContent: 'left', marginTop: '10px', paddingTop: '5px' }}>
            <CheckboxListSecondary filteredItems={filteredItems} setSelectedItems={setSelectedItems} />
          </div>
          <div style={{ display: 'flex', position: 'fixed', bottom: '5%', flexDirection: 'row', width: '100%', maxWidth: '400px', justifyContent: 'space-around' }}>
            <Button variant="outlined" onClick={() => { history.go(-1) }}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => { handleReport() }}>
              Gerar relatório
            </Button>
          </div>
        </>
      )}

    </div >
  );
};

export default DevicesSpeed;
