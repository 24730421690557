import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';

const CarrierImage = ({ position, mh = 22, mw = 17 }) => {
  const operadoras = [
    { code: 32, logo: 'algartelecom', name: 'Algar Telecom' },
    { code: 33, logo: 'algartelecom', name: 'Algar Telecom' },
    { code: 34, logo: 'algartelecom', name: 'Algar Telecom' },
    { code: 26, logo: 'americanet', name: 'Americanet' },
    { code: 299, logo: 'arqia', name: 'ARQIA' },
    { code: 5, logo: 'claro', name: 'Claro' },
    { code: 38, logo: 'claro', name: 'Claro' },
    { code: 21, logo: 'ligue', name: 'Ligue' },
    { code: 39, logo: 'nextel', name: 'Nextel' },
    { code: 0, logo: 'nextel', name: 'Nextel' },
    { code: 16, logo: 'oimovel', name: 'Oi Movel' },
    { code: 31, logo: 'oimovel', name: 'Oi Movel' },
    { code: 15, logo: 'sercomtel', name: 'Sercomtel' },
    { code: 3, logo: 'tim', name: 'TIM' },
    { code: 2, logo: 'tim', name: 'TIM' },
    { code: 4, logo: 'tim', name: 'TIM' },
    { code: 54, logo: 'tim', name: 'TIM' },
    { code: 11, logo: 'vivo', name: 'Vivo' },
    { code: 23, logo: 'vivo', name: 'Vivo' },
    { code: 6, logo: 'vivo', name: 'Vivo' },
    { code: 10, logo: 'vivo', name: 'Vivo' },
  ];

  const [carrier, setCarrier] = useState({ code: 909090, logo: 'nocarrier', name: 'Não informado pelo Rastreador' });

  const checkCarrier = (position) => {
    // console.log(position);
    // console.log(position.network.radioType, position.network.cellTowers[0].mobileNetworkCode);
    try {
      operadoras.filter((it) => it.code === position.network.cellTowers[0].mobileNetworkCode).map((mnc) => {
        setCarrier(mnc);
        return 1;
        // console.log(mnc.name);
      });
    } catch {
      console.log('SEM INFO OPERADORA');
    }
    /*
    {
      "radioType": "gsm",
      "considerIp": false,
      "cellTowers": [
          {
              "cellId": 33803,
              "locationAreaCode": 38252,
              "mobileCountryCode": 724,
              "mobileNetworkCode": 2
          }
      ]
    }
    */
  };

  useEffect(() => {
    checkCarrier(position);
  }, [position]);

  return (
    <Tooltip title={carrier.name}>
      <IconButton
        size="small"
      >
        <img style={{ maxHeight: mh, maxWidth: mw, borderRadius: '10px' }} src={`/carrierIcons/${(carrier.logo).toLowerCase()}.png`} alt="" />
      </IconButton>
    </Tooltip>
  );
};

export default CarrierImage;
