import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { useTheme } from '@mui/material/styles';
// import LogoImage from './LogoImage';
import LoginVideo from './LoginVideo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },

  paper: {
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',

    // zIndex: 500,
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    // zIndex: 5002,
    // width: '100%',
  },
  dashboardLogo: {
    maxHeight: '120px',
    maxWidth: '200px',
    margin: '20px',
    marginTop: '35px',

  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  // const theme = useTheme();

  const [logoImage, setLogoImage] = useState(null);
  useEffect(() => {
    if (logoImage === null) {
      const url = (window.location.href).split('/')[2];

      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/domain/${url}`, {
        method: 'GET',
      }).then((response) => response.json())
        .then((response) => {
          setLogoImage(`${process.env.REACT_APP_MEDIA_SERVER}/get/${response.urlLogotipo}`);
          document.title = response.nomeFantasia;
          console.log(response);
        });
    }
  }, [logoImage]);
  return (
    <main className={classes.root}>
      <LoginVideo />
      <div style={{ zIndex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}>
        <Paper className={classes.paper}>
          <img alt="" className={classes.dashboardLogo} src={logoImage} />
          <form className={classes.form}>
            {children}
          </form>
        </Paper>
      </div>
    </main>
  );
};

export default LoginLayout;
