import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FormControl, InputLabel, Select, MenuItem, Table, TableHead, TableRow, TableBody, TableCell, Button, Container, CircularProgress, Typography,
} from '@mui/material';
import {
  formatDistance, formatHours, formatSpeed, formatVolume, formatTime,
} from '../../common/util/formatter';
import ReportFilter from '../components/ReportFilter';
import { useAttributePreference, usePreference } from '../../common/util/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';
import PageLayout from '../../common/components/PageLayout';
import ReportsMenu from '../components/ReportsMenu';
import usePersistedState from '../../common/util/usePersistedState';
import ColumnSelect from '../components/ColumnSelect';
import { useCatch } from '../../reactHelper';
import useReportStyles from '../common/useReportStyles';
import TableShimmer from '../../common/components/TableShimmer';
import scheduleReport from '../common/scheduleReport';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const columnsArray = [
  ['startTime', 'reportStartDate'],
  ['distance', 'sharedDistance'],
  ['startOdometer', 'reportStartOdometer'],
  ['endOdometer', 'reportEndOdometer'],
  ['averageSpeed', 'reportAverageSpeed'],
  ['maxSpeed', 'reportMaximumSpeed'],
  ['engineHours', 'reportEngineHours'],
  ['spentFuel', 'reportSpentFuel'],
];
const columnsMap = new Map(columnsArray);

const UsersSummary = () => {
  const navigate = useNavigate();
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  // const [columns, setColumns] = usePersistedState('usersSummary', ['name', 'distance', 'averageSpeed']);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [company, setCompany] = useState(null);

  const formatValue = (item, key) => {
    switch (key) {
      case 'deviceId':
        return devices[item[key]].name;
      case 'startTime':
        return formatTime(item[key], 'date', hours12);
      case 'startOdometer':
      case 'endOdometer':
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'averageSpeed':
      case 'maxSpeed':
        return formatSpeed(item[key], speedUnit, t);
      case 'engineHours':
        return formatHours(item[key]);
      case 'spentFuel':
        return formatVolume(item[key], volumeUnit, t);
      default:
        return item[key];
    }
  };

  useEffect(() => {

    const url = (window.location.href).split('/')[2];

    fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/domain/${url}`, {
      method: 'GET',
    }).then((response) => response.json())
      .then((response) => {
        setCompany(response);
        console.log(response);
        fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/${response.urlLogotipo}`)
          .then(response => response.blob())
          .then(blob => {
            var reader = new FileReader();
            reader.onload = function () { setImg(this.result); }; // <--- `this.result` contains a base64 data URI
            reader.readAsDataURL(blob);
          });

      });
  }, []);

  const getRelations = async (userId, endpoint) => {
    return await fetch(`/api/${endpoint}?userId=${userId}`, {
      "headers": {
        "accept": "application/json",
        "cache-control": "no-cache",
        "pragma": "no-cache"
      },
      "method": "GET",
      "credentials": "include"
    }).then((res) => res.json())
      .then((res) => {
        return res;
      });
  }

  const getUsers = async () => {
    return await fetch('/api/users', {
      "headers": {
        "accept": "application/json",
        "cache-control": "no-cache",
        "pragma": "no-cache"
      },
      "method": "GET",
      "credentials": "include"
    }).then((res) => res.json())
      .then((res) => {
        // console.log(res);
        return res;
      });
  }

  const createPDF = async (userList) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
      {
        // style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto'],
          heights: [100],
          body: [
            [
              {
                border: [false, false, false, false],
                // fillColor: '#dddddd',
                image: img,
                width: 120,
                opacity: 0.7
              },
              {
                border: [false, false, false, false],
                // fillColor: '#dddddd',
                color: '#292929',
                text: [
                  { text: "Relatório Sintético de Usuários", fontSize: 15 },
                  { text: `\n\n${new Date().toLocaleString('pt-BR')}`, fontSize: 9, margin: [0, 10, 0, 20] },
                  // { text: `\nVocê tem ${userList.length} clientes cadastrados`, fontSize: 9, margin: [0, 20, 0, 10] },
                ],
                alignment: 'center',
                margin: [0, 10, 0, 0],
              },
              {
                border: [false, false, false, false],
                // fillColor: '#c0c0c0',
                color: '#c0c0c0',
                text: [
                  { text: company.nomeFantasia.concat("\n"), italics: true, fontSize: 13 },
                  { text: company.email.concat("\n"), fontSize: 11 },
                  { text: company.contato.concat("\n"), fontSize: 11 },
                  { text: `https://${(window.location.href).split('/')[2]}\n`, fontSize: 11 },
                  // { text: `${company.email}\n` },
                  // { text: `${company.contato}\n` },
                ],
                // tesxt: `${company.nomeFantasia}\nhttps://${(window.location.href).split('/')[2]}\n${company.email}\n${company.contato}`,
                alignment: 'right',
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
        margin: [25, 15, 25, 15],
      },
    ];

    // const dados = await getUsers();
    // console.log(userList);
    const x = await Promise.all(
      userList.map(async (user) => {
        const devices = await getRelations(user.id, 'devices');
        const groups = await getRelations(user.id, 'groups');
        let rgIe = '';
        let cpfCnpj = '';

        try {
          rgIe = JSON.parse(user?.attributes?.extraFields).rgIe;
          cpfCnpj = JSON.parse(user?.attributes?.extraFields).cpfCnpj;
        } catch {

        }

        return [
          { text: user.name, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
          { text: user.email, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
          { text: user.phone, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
          { text: devices.length, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false], alignment: 'center' },
          { text: groups.length, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false], alignment: 'center' },
          { text: rgIe, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false], alignment: 'right' },
          { text: cpfCnpj, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false], alignment: 'right' },
        ];
        // console.log(user.name, devices.length);
      })
    );

    /* 
        const x = userList.map((user) => {
          let rgIe = '';
          let cpfCnpj = '';
    
          try {
            rgIe = JSON.parse(user?.attributes?.extraFields).rgIe;
            cpfCnpj = JSON.parse(user?.attributes?.extraFields).cpfCnpj;
          } catch {
    
          }
    
    
          // const devices = await getRelations(user.id, 'devices');
          return [
            { text: user.name, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
            { text: user.email, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
            { text: user.phone, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
            { text: 'devices.length', fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
            { text: rgIe, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
            { text: cpfCnpj, fontSize: 8, margin: [0, 2, 0, 2], border: [false, false, false, false] },
          ]
        })
     */
    const details = [
      {
        text: [
          { text: `\nVocê tem ${userList.length} clientes cadastrados`, fontSize: 9 },
        ],
        margin: [0, 2, 0, 10],
      },
      {
        table: {
          widths: ['*', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
          headerRows: 1,
          body: [
            [
              { text: 'Nome', fontSize: 9, border: [false, false, false, true], alignment: 'center' },
              { text: 'Email', fontSize: 9, border: [false, false, false, true], alignment: 'center' },
              { text: 'Telefone', fontSize: 9, border: [false, false, false, true], alignment: 'center' },
              { text: 'Objetos', fontSize: 9, border: [false, false, false, true], alignment: 'center' },
              { text: 'Grupos', fontSize: 9, border: [false, false, false, true], alignment: 'center' },
              { text: 'RG/IE', fontSize: 9, border: [false, false, false, true], alignment: 'center' },
              { text: 'CPF/CNPJ', fontSize: 9, border: [false, false, false, true], alignment: 'center' }
            ],
            ...x,

          ]
        },
        // B7E9F7
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex % 2 === 0) ? '#D8F9FF' : null;
          },
        }
      }
    ];

    const Footer = (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} / ${pageCount}`,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0],
        }
      ];
    };

    const docDefinitions = {
      pageSize: 'A4',
      pageMargins: [15, 80, 15, 40], // 
      pageOrientation: 'landscape',

      header: [reportTitle],
      content: [details],
      footer: Footer,
    };

    pdfMake.createPdf(docDefinitions).download('file.pdf', function () { handleFinish(); });
  }

  const handleFinish = () => {
    setLoading(false);
    history.go(-1);
  }

  const handleReport = async () => {
    setLoading(true);
    const userList = await getUsers()

    userList.map(async (user) => {
      // const devices = await getRelations(user.id, 'devices');
      // const groups = await getRelations(user.id, 'groups');
      // const geofences = await getRelations(user.id, 'geofences');
      // const notifications = await getRelations(user.id, 'notifications');
      // const calendars = await getRelations(user.id, 'calendars');
      // const users = await getRelations(user.id, 'users');
      // const computed = await getRelations(user.id, 'attributes/computed');
      // const drivers = await getRelations(user.id, 'drivers');
      // const commands = await getRelations(user.id, 'commands');
      // const maintenance = await getRelations(user.id, 'maintenance');
      // console.log(user.id, user.name, devices, groups, geofences, notifications, calendars, users, computed, drivers, commands, maintenance);
      // console.log(user.id, user.name, devices.length, groups.length, geofences.length, notifications.length, calendars.length, users.length, computed.length, drivers.length, commands.length, maintenance.length);
      // console.log(user.id, user.name, devices.length);
    });

    // `${process.env.REACT_APP_MEDIA_SERVER}/get/${response.urlLogotipo}`

    createPDF(userList);
    // history.go(-1);

  }

  return (
    <div style={{ flexDirection: 'column', display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
      {img !== null && <img crossOrigin="anonymous" id="baseLogo" onLoad={(event) => event.target.style.display = 'inline-block'} onError={(event) => event.target.style.display = 'none'} style={{ maxHeight: '60px', position: 'fixed', top: '10%' }} src={img} alt="Uploaded Image" />}
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress style={{ opacity: 0.7, color: 'lightblue', margin: '20px', width: '80px', height: '80px' }} />
          <Typography variant="caption" color="textSecondary" style={{ marginTop: '5px', fontSize: '13px', maxWidth: '80%' }}>
            Gerando Relatório Sintético de Usuários, por favor aguarde...
          </Typography>
        </div>
      ) : (
        <>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: '5px', fontSize: '17px', maxWidth: '80%' }}>
            Relatório Sintético de Usuários
          </Typography>
          <Typography variant="caption" color="textSecondary" style={{ marginTop: '5px', fontSize: '13px', maxWidth: '80%' }}>
            Pode demorar de 2 a 5 minutos para gerar os seus relatórios, conforme o número de registros.
          </Typography>
          <div style={{ display: 'flex', position: 'fixed', bottom: '5%', flexDirection: 'row', width: '100%', maxWidth: '400px', justifyContent: 'space-around' }}>
            <Button variant="outlined" onClick={() => { history.go(-1) }}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => { handleReport() }}>
              Gerar relatório
            </Button>
          </div>
        </>
      )}

    </div >
  );
};

export default UsersSummary;
