import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {
  Drawer, IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { formatNotificationTitle } from '../common/util/formatter';
// import { useTranslation } from '../common/components/LocalizationProvider';
// import { MenuActions } from '../store';
// import { usePreference } from '../common/util/preferences';
import SettingsMenu from '../settings/components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '280px',
  },
  dashboardLogo: {
    maxHeight: '30px',
    margin: '3px',
  },
  toolbar: {
    width: '280px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const MenuDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const t = useTranslation();

  // const hours12 = usePreference('twelveHourFormat');

  // const devices = useSelector((state) => state.devices.items);

  // const events = useSelector((state) => state.events.items);

  const [logoImage, setLogoImage] = useState(null);
  useEffect(() => {
    if (logoImage === null) {
      const url = (window.location.href).split('/')[2];

      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/domain/${url}`, {
        method: 'GET',
      }).then((response) => response.json())
        .then((response) => {
          setLogoImage(`${process.env.REACT_APP_MEDIA_SERVER}/get/${response.urlLogotipo}`);
          document.title = response.nomeFantasia;
          console.log(response);
        });
    }
  }, [logoImage]);

  return (
    <Drawer
      sx={{
        width: '280px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '280px',
          boxSizing: 'border-box',
        },
      }}
      variant="temporary"
      className="drawer"
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '5px' }}>
        <img alt="" className={classes.dashboardLogo} src={logoImage} />
        <IconButton onClick={() => onClose(false)}>
          <MenuOpenIcon />
        </IconButton>
      </div>
      <SettingsMenu />
    </Drawer>
  );
};

export default MenuDrawer;
