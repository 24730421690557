import React, {
  useState, useCallback, useEffect,
} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import InventoryIcon from '@mui/icons-material/Inventory';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Paper, IconButton, Button, Typography } from '@mui/material';
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
// import Tabs from '@mui/material/Tabs';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import DeviceList from './DeviceList';
import BottomMenu from '../common/components/BottomMenu';
import StatusCard from '../common/components/StatusCard';
import { devicesActions } from '../store';
import usePersistedState from '../common/util/usePersistedState';
import EventsDrawer from './EventsDrawer';
import EventsList from './EventsList';
import MenuDrawer from './MenuDrawer';
import ProfileMenu from './ProfileMenu';
import useFilter from './useFilter';
import MainToolbar from './MainToolbar';
import MainMap from './MainMap';
// import BasicModal from './BasicModal';
import { useAttributePreference } from '../common/util/preferences';
import FirstTimeAccess from './FirstTimeAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fixList: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 100px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 150px)',
    },
  },
  sidebar: {
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    // height: 'calc(100%)', // ${theme.spacing(3)})`,
    zIndex: 10,
    borderTopRightRadius: '25px',
    [theme.breakpoints.up('md')]: {
      top: '50px',
      marginTop: '25px',
      height: 'calc(100% - 75px)',
      // backgroundColor: 'red',
      // margin: theme.spacing(1.5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '50px',
      top: '0px',
      height: 'calc(100% - 50px)',
      // margin: theme.spacing(1.5),
    },
  },
  middle: {
    flex: 1,
    display: 'grid',
    position: 'absolute',
    height: '100%',
    // backgroundColor: 'yellow',
    // bottom: 0,
    width: '350px',
    maxWidth: '350px',
    // left: 0,
    // display: 'none',
  },
  contentList: {
    pointerEvents: 'auto',
    height: '100%',
    // backgroundColor: 'cyan',
    gridArea: '1 / 1',
    zIndex: 4,
    boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.9)',
    borderTopRightRadius: '5px',
    // height: 'calc(100%)',
  },
  dashboardLogo: {
    maxHeight: '40px',
    maxWidth: '140px',
    margin: '3px',
  },
  customNavBar: {
    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.6)',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    margin: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    // backgroundColor: 'red',
    zIndex: 10,
    width: '100vw',
    height: '50px',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px',
  },
  header: {
    pointerEvents: 'auto',
    zIndex: 6,
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
    display: 'none',
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  customButtonActive: {
    borderBottom: '2px solid',
    backgroundColor: 'white',
    fontWeight: '400',
    marginRight: '5px',
    maxHeight: '35px',
    fontSize: '13px',
    textTransform: 'capitalize',
    borderRadius: '0px',
  },
  customButton: {
    borderBottom: '2px solid transparent',
    backgroundColor: 'transparent',
    fontWeight: '400',
    marginRight: '5px',
    maxHeight: '35px',
    fontSize: '13px',
    textTransform: 'capitalize',
    borderRadius: '0px',
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const mapOnSelect = useAttributePreference('mapOnSelect', true);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.session.positions);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId);

  const [filteredDevices, setFilteredDevices] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = usePersistedState('filter', {
    statuses: [],
    groups: [],
  });
  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);

  const [devicesOpen, setDevicesOpen] = useState(desktop);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [openFTA, setOpenFTA] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  // const extPermissions = useSelector((state) => state.session.permissions);

  const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);
  const [logoImage, setLogoImage] = useState(null);

  const cUser = useSelector((state) => state.session.user);
  const [extPermissions, setExtPermissions] = useState({
    userId: -1,
    all: true,
    mainMenu: true,
    preferences: true,
    notifications: true,
    account: true,
    devices: true,
    geofence: true,
    groups: true,
    drivers: true,
    calendars: true,
    calculatedAttr: true,
    maintenance: true,
    savedCommands: true,
    server: true,
    users: true,
    editExtPermissions: true,
    logs: true,
    billing: true,
    crm: true,
    easyconf: true,
    reports: true,
    about: true,
    blockDevice: true,
    moreOptions: true,
    replay: true,
    editDevice: true,
    removeDevice: true,
    shareDevice: true,
  });

  useEffect(() => {
    if (cUser !== null && extPermissions.userId === -9999) {
      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/permissions/${cUser.id}`, { method: 'GET' })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setExtPermissions(res);
        })
        .catch((err) => {
          console.log('Erro no get das permissões', err);
        });
    }
  }, [cUser]);

  const handleFirstLogin = (user) => {
    const x = JSON.parse(JSON.stringify(user));
    x.attributes.firstTimeAccess = false;
    fetch(`/api/users/${x.id}`, { method: 'PUT', body: JSON.stringify(x), headers: { 'Content-Type': 'application/json' } })
      .then((res) => res.json())
      .then((res) => {
        // setOpenFTA(true);
        // alert('nunca logou antes');
        console.log(res);
        setOpenFTA(false);
      });
    // console.log(x);
    /*
        const payload = {
          id: -1,
          name: tempPass,
          email: tempPass,
          phone: '',
          readonly: true,
          // administrator: false,
          map: '',
          latitude: 0,
          longitude: 0,
          zoom: 0,
          password: tempPass,
          // twelveHourFormat: true,
          // coordinateFormat: '',
          disabled: false,
          expirationTime: futureDate,
          // deviceLimit: 1,
          // userLimit: 1,
          deviceReadonly: true,
          limitCommands: true,
          // fixedEmail: true,
          disableReports: true,
          poiLayer: '',
          attributes: {},
        };
         */
  };

  useEffect(() => {
    if (logoImage === null) {
      const url = (window.location.href).split('/')[2];

      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/domain/${url}`, {
        method: 'GET',
        // mode: 'no-cors',
      }).then((response) => response.json())
        .then((response) => {
          localStorage.setItem('revenda', JSON.stringify(response));
          setLogoImage(`${process.env.REACT_APP_MEDIA_SERVER}/get/${response.urlLogotipo}`);
          document.title = response.nomeFantasia;
          // console.log(response);
        });
    }
  }, [logoImage]);
  // const onMenuClick = useCallback(() => setMenuOpen(true), [setMenuOpen]);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  useFilter(keyword, filter, filterSort, filterMap, positions, setFilteredDevices, setFilteredPositions);

  const [value, setValue] = React.useState(1);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (typeof cUser.attributes?.firstTimeAccess === 'undefined') {
      fetch('/api/server', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
        .then((res) => res.json())
        .then((res) => {
          if (res.attributes?.forcePasswordChange === true) {
            setOpenFTA(true);
            // alert('nunca logou antes');
            // console.log(res);
          }
        });
    } else if (cUser.attributes?.firstTimeAccess === true) {
      setOpenFTA(true);
    }
    // console.log(extPermissions);
  }, []);

  return (
    <div className={classes.root}>
      <FirstTimeAccess openFTA={openFTA} setOpenFTA={setOpenFTA}>
        <div style={{ maxWidth: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h6">
            Parabéns por seu primeiro acesso!
          </Typography>
          <Typography variant="body2" style={{ marginTop: '20px' }}>
            Caso deseje trocar a sua senha, basta clicar em Editar Perfil. Para manter a sua senha atual clique em Manter senha.
          </Typography>
          <div style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
            <Button variant="contained" size="small" onClick={() => { handleFirstLogin(cUser); window.location.href = `/settings/user/${cUser.id}`; }}>
              Editar Perfil
            </Button>
            <Button variant="outlined" size="small" onClick={() => { handleFirstLogin(cUser); }}>
              Manter Senha
            </Button>
          </div>
        </div>
      </FirstTimeAccess>
      <div className={classes.customNavBar}>
        <div style={{ maxWidth: '200px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton style={{ display: extPermissions.mainMenu ? 'block' : 'none' }} onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>

          <img alt="" className={classes.dashboardLogo} src={logoImage} />
        </div>
        <ProfileMenu />
      </div>

      <MenuDrawer open={menuOpen} onClose={() => setMenuOpen(false)} />
      {desktop && (
        <MainMap
          filteredPositions={filteredPositions}
          selectedPosition={selectedPosition}
          onEventsClick={onEventsClick}
        />
      )}
      <IconButton style={{ boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.9)', position: 'absolute', left: !devicesOpen ? '5px' : '-300px', backgroundColor: 'white', top: '50%', zIndex: 300 }} onClick={() => setDevicesOpen(!devicesOpen)}>
        {devicesOpen ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowRightIcon />}
      </IconButton>

      <div className={classes.sidebar}>
        <div className={classes.middle}>
          {!desktop && (
            <div className={classes.contentMap}>
              <MainMap
                filteredPositions={filteredPositions}
                selectedPosition={selectedPosition}
                onEventsClick={onEventsClick}
              />
            </div>
          )}
          <Paper square className={classes.contentList} style={devicesOpen ? {} : { visibility: 'hidden' }}>
            <div style={{

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'left',
              backgroundColor: '#FAFAFA',
              borderTopRightRadius: '25px',

            }}
            >

              <Button
                className={value === 1 ? classes.customButtonActive : classes.customButton}
                onClick={() => handleChange(1)}
                size="small"
              >
                Objetos
                (
                {filteredDevices.length}
                )
              </Button>
              <Button
                className={value === 2 ? classes.customButtonActive : classes.customButton}
                onClick={() => handleChange(2)}
                size="small"
              >
                Eventos
              </Button>
              <Button
                style={{ display: 'none' }}
                className={value === 3 ? classes.customButtonActive : classes.customButton}
                onClick={() => handleChange(3)}
                size="small"
              >
                Locais
              </Button>
              <Button
                style={{ display: 'none' }}
                className={value === 4 ? classes.customButtonActive : classes.customButton}
                onClick={() => handleChange(4)}
                size="small"
              >
                Relatórios
              </Button>
            </div>
            {value === 1 && (
              <div className={classes.fixList}>
                <MainToolbar
                  filteredDevices={filteredDevices}
                  devicesOpen={devicesOpen}
                  setDevicesOpen={setDevicesOpen}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filter={filter}
                  setFilter={setFilter}
                  filterSort={filterSort}
                  setFilterSort={setFilterSort}
                  filterMap={filterMap}
                  setFilterMap={setFilterMap}
                />
                <DeviceList devices={filteredDevices} />
              </div>
            )}
            {value === 2 && <EventsList />}
            {value === 3 && 'Locais'}
            {value === 4 && 'Relatórios'}

          </Paper>
        </div>
        {!desktop && (
          <div className={classes.footer}>
            <BottomMenu style={{ display: 'none' }} />
          </div>
        )}
      </div>
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      {selectedDeviceId && (
        <StatusCard
          deviceId={selectedDeviceId}
          position={selectedPosition}
          onClose={() => dispatch(devicesActions.selectId(null))}
          desktopPadding={theme.dimensions.drawerWidthDesktop}
        />
      )}
    </div>
  );
};

export default MainPage;
