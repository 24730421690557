import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Button,
  Container,
  Stack,
} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import { useTranslation } from '../common/components/LocalizationProvider';
import useUserAttributes from '../common/attributes/useUserAttributes';
import { sessionActions } from '../store';
import SelectField from '../common/components/SelectField';
import SettingsMenu from './components/SettingsMenu';
import useCommonUserAttributes from '../common/attributes/useCommonUserAttributes';
import { useAdministrator, useRestriction, useManager } from '../common/util/permissions';
import useQuery from '../common/util/useQuery';
import { useCatch } from '../reactHelper';
import useMapStyles from '../map/core/useMapStyles';
import { map } from '../map/core/MapView';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const UserPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();
  const manager = useManager();
  const fixedEmail = useRestriction('fixedEmail');

  const currentUser = useSelector((state) => state.session.user);
  const registrationEnabled = useSelector((state) => state.session.server.registration);
  const openIdForced = useSelector((state) => state.session.server.openIdForce);

  const mapStyles = useMapStyles();
  const commonUserAttributes = useCommonUserAttributes(t);
  const userAttributes = useUserAttributes(t);
  const [domainError, setDomainError] = useState(false);

  const [extraFields, setExtraFields] = useState({
    fullName: '',
    cpfCnpj: '',
    rgIe: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
  });

  const handleCep = () => {

    if (parseInt(extraFields.cep.length) <= 7) {
      console.log(parseInt(extraFields.cep.length) <= 7, extraFields.cep.length);
      return;
    } else {
      console.log("Aqui...")
      fetch(`${process.env.REACT_APP_CONSULTA_CEP}/${extraFields.cep}/json/`, { method: 'GET' })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setExtraFields({ ...extraFields, logradouro: res.logradouro, bairro: res.bairro, cidade: res.localidade, estado: res.uf });
        });
    }
    // alert(cep);
  }

  const { id } = useParams();
  const [item, setItem] = useState(id === currentUser.id.toString() ? currentUser : null);

  const [deleteEmail, setDeleteEmail] = useState();
  const [deleteFailed, setDeleteFailed] = useState(false);
  // const [validDomain, setValidDomain] = useState(false);

  const [img, setImg] = useState(null);
  const [isReseller, setIsReseller] = useState(false);
  const [reseller, setReseller] = useState({
    nomeFantasia: '',
    urlAcesso: '',
    urlLogotipo: '',
    contato: '',
    email: '',
    isReseller: true,
    limiteUsuarios: 10,
    limiteObjetos: 10,
    plano: 'Futuro',
  });

  const handleDelete = useCatch(async () => {
    if (deleteEmail === currentUser.email) {
      setDeleteFailed(false);
      const response = await fetch(`/api/users/${currentUser.id}`, { method: 'DELETE' });
      if (response.ok) {
        navigate('/login');
        dispatch(sessionActions.updateUser(null));
      } else {
        throw Error(await response.text());
      }
    } else {
      setDeleteFailed(true);
    }
  });

  const handleReseller = (stt) => {
    setIsReseller(stt);
    if (!item.attributes.reseller) {
      // console.log("Não existe");
      item.attributes.reseller = JSON.stringify(reseller);
    } else {
      setReseller(JSON.parse(item.attributes.reseller));
    }
  }

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const validateDomain = async () => {
    return await fetch(`${process.env.REACT_APP_MEDIA_SERVER}/check/domain/${reseller.urlAcesso}`, { method: 'GET' })
      .then(res => res.text())
      .then(res => {
        // console.log(res);
        if (res == "true") {
          return true;
        }
      })
      .catch(err => {
        return false;
      });
  }; // função

  const validateImage = async () => {
    return await fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/${reseller.urlLogotipo}`, { method: 'GET' })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        return res.ok;
      });

  }; // função

  const handleSaveUser = (idUser) => {
    // alert(JSON.parse(item.attributes?.reseller).isReseller);
    console.log(item);
    console.log("entrou aqui");
    var dataCrm = new FormData();
    dataCrm.append('isSame', item.id === currentUser.id);
    dataCrm.append('isAdmin', item.administrator);
    dataCrm.append('isManager', item.deviceLimit >= 5 || item.administrator);
    dataCrm.append('isReseller', typeof item.attributes?.reseller !== 'undefined' ? JSON.parse(item.attributes?.reseller).isReseller : false);
    dataCrm.append('domain', (window.location.href).split('/')[2]);
    dataCrm.append('parent', currentUser.id);
    dataCrm.append('child', idUser);

    fetch(`${process.env.REACT_APP_CRM_SERVER}/saveUser`, {
      method: 'POST',
      body: new URLSearchParams(dataCrm),
    }).then(response => response.json())
      .then(async response => {
        console.log("Response: ", response)
      });
  }

  const handleFileUpload = async (element) => {

    if (!await validateDomain()) {
      alert('Domínio inválido ou ainda não propagado no DNS, tente novamente em alguns minutos por favor.');
      return;
    }

    if (!validateEmail(reseller.email)) {
      alert('Por favor, verifique seu endereço de email');
      return;
    }

    var data = new FormData()
    data.append('file', element.target.files[0]);
    data.append('domain', reseller.urlAcesso);
    data.append('nomeFantasia', reseller.nomeFantasia);
    data.append('contato', reseller.contato);
    data.append('email', reseller.email);
    data.append('isReseller', reseller.isReseller);
    data.append('limiteUsuarios', reseller.limiteUsuarios);
    data.append('limiteObjetos', reseller.limiteObjetos);
    data.append('plano', reseller.plano);

    fetch(process.env.REACT_APP_MEDIA_SERVER + '/upload', {
      method: 'POST',
      body: data,
      // mode: 'no-cors',
    }).then(response => response.json())
      .then(async response => {
        setReseller({ ...reseller, urlLogotipo: response.fileDetails.filename });
        //console.log(await validateImage());
        // item.attributes.reseller = JSON.stringify(reseller);
        // console.log("Response: ", response)
      });

    /*
  try {
    setImg(`${process.env.REACT_APP_MEDIA_SERVER}/get/${reseller.urlLogotipo}`);
  } catch {
    setImg(null);
    return;
  } 
  */
  }

  const query = useQuery();
  const [queryHandled, setQueryHandled] = useState(false);
  const attribute = query.get('attribute');

  const [firstLoad, setFirstLoad] = useState(true);

  const [extPermissions, setExtPermissions] = useState({
    userId: -1,
    all: true,
    mainMenu: true,
    preferences: true,
    notifications: true,
    account: true,
    devices: true,
    geofence: true,
    groups: true,
    drivers: true,
    calendars: true,
    calculatedAttr: true,
    maintenance: true,
    savedCommands: true,
    server: true,
    users: true,
    editExtPermissions: true,
    logs: true,
    billing: true,
    crm: true,
    easyconf: true,
    reports: true,
    about: true,
    blockDevice: true,
    moreOptions: true,
    replay: true,
    editDevice: true,
    removeDevice: true,
    shareDevice: true,
    dvr: true,
  });

  const handleListPermissions = () => {
    fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/permissions/${item.id}`, { method: 'GET' })
      .then(res => res.json())
      .then(res => {
        // console.log('OOOOOOOOOOOOO', res, item.id);
        setExtPermissions(res);
        // setExtPermissions({ ...extPermissions, userId: item.id })
      })
      .catch(err => {
        console.log("Erro no get das permissões");
      });
  }

  const handleExtraFields = () => {
    try {
      setExtraFields(JSON.parse(item.attributes.extraFields));
    } catch {
      console.log('No extra fields');
    }
    // console.log('Passou aqui');
  }

  const badFix = () => {
    if (firstLoad) {
      handleListPermissions();
      handleExtraFields();
      setFirstLoad(false);
    }
  }

  const handleSaveExtPermissions = (userId) => {
    setExtPermissions({ ...extPermissions, userId: userId });
    fetch(`${process.env.REACT_APP_MEDIA_SERVER}/permissions`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(extPermissions),
    }).then(response => response.json())
      .then(async response => {
        console.log("Response: ", response)
        dispatch(sessionActions.updatePermissions(extPermissions));
      });
    // console.log('KKKKKKKK', extPermissions, item.id);

  };

  const handleAll = () => {
    if (!extPermissions.all) {
      Object.keys(extPermissions).map(i => {
        extPermissions[i] = true;
      });
    } else {
      Object.keys(extPermissions).map(i => {
        extPermissions[i] = false;
      });
    }
  };

  useEffect(() => {
    if (!queryHandled && item && attribute) {
      if (!item.attributes.hasOwnProperty('attribute')) {
        const updatedAttributes = { ...item.attributes };
        updatedAttributes[attribute] = '';
        setItem({ ...item, attributes: updatedAttributes });
      }
      setQueryHandled(true);
    }

    if (item !== null) {
      if (item?.attributes?.reseller) {
        handleReseller(true);
        // console.log(JSON.parse(item.attributes.reseller));
      }
      setExtPermissions({ ...extPermissions, userId: item.id });
    }
  }, [item, queryHandled, setQueryHandled, attribute]);

  useEffect(() => {
    try {
      item.attributes.reseller = JSON.stringify(reseller);
      setImg(process.env.REACT_APP_MEDIA_SERVER + '/get/' + reseller.urlLogotipo);

      if (!isReseller) {
        item.attributes.reseller = null;
      }

    } catch {
      // console.log('Não Pronto');
    }

  }, [reseller]);

  useEffect(() => {
    try {
      setItem({ ...item, attributes: { ...item.attributes, extraFields: JSON.stringify(extraFields) } });
    } catch {
      // console.log('Não Pronto');
    }

    // console.log(extraFields);
  }, [extraFields]);

  useEffect(() => {

    if (item !== null && extPermissions.userId === -1) {
      // console.log('PPPPPPPPPPPP', item);
      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/permissions/${item.id}`, { method: 'GET' })
        .then(res => res.json())
        .then(res => {
          // console.log('OOOOOOOOOOOOO', res);
          setExtPermissions(res);
          // setExtPermissions({ ...extPermissions, userId: item.id })
        })
        .catch(err => {
          console.log("Erro no get das permissões");
        });
    }
  }, [item]);


  const getPermissions = async () => {
    return await fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/permissions/${item.id}`, { method: 'GET' })
      .then(res => res.json())
      .then(res => {
        // console.log(res);
        return res;
      })
      .catch(err => {
        console.log("Erro no get das permissões");
      });
  }; // função


  const onItemSaved = (result) => {
    if (result.id === currentUser.id) {
      dispatch(sessionActions.updateUser(result));
    }
    // handleSaveUser(result.id);
    handleSaveExtPermissions(result.id);
    // alert('aqui salvando...');
  };


  const validate = () => item && item.name && item.email && (item.id || item.password);

  // console.log('Revenda: ', revenda);
  // console.log('useCurrentUser().attributes', useCurrentUser().attributes);
  // console.log('useCurrentUser().attributes', useCurrentUser().attributes);
  // console.log('Editando: ', currentUser);

  // console.log(JSON.stringify(reseller));

  /*
  if (item.attributes.reseller) {
    // alert('é Revenda');
    setReseller({
      urlAcesso: item.attributes.reseller.urlAcesso,
      urlLogotipo: item.attributes.reseller.urlLogotipo,
      nomeFantasia: item.attributes.reseller.nomeFantasia,
      contato: item.attributes.reseller.contato,
      isReseller: item.attributes.reseller.isReseller,
    });
  }
  const to = setTimeout(() => {
    setRevenda({ ...revenda, isRevenda: true });
    clearTimeout(to);
  }, 5000);
  */


  /*
  {
  "id": 0,
  "description": "string",
  "attribute": "string",
  "expression": "string",
  "type": "string"
  }  
  */

  return (
    <EditItemView
      endpoint="users"
      item={item}
      setItem={setItem}
      defaultItem={admin ? { deviceLimit: -1 } : {}}
      validate={validate}
      onItemSaved={onItemSaved}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'settingsUser']}
    >
      {item && (
        <div onClick={() => { badFix() }}>
          <Accordion defaultExpanded={!attribute}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <TextField
                value={item.login || ''}
                onChange={(event) => setItem({ ...item, login: event.target.value })}
                label="Login"
              />
              <TextField
                value={item.email || ''}
                onChange={(event) => setItem({ ...item, email: event.target.value })}
                label={t('userEmail')}
                disabled={fixedEmail}
              />

              {!openIdForced && (
                <TextField
                  type="password"
                  onChange={(event) => setItem({ ...item, password: event.target.value })}
                  label={t('userPassword')}
                />
              )}

            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Permissões Avançadas
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              {extPermissions && (
                <>
                  <FormControlLabel style={{ display: 'none' }}
                    control={<Checkbox checked={extPermissions.all} onClick={() => { handleAll() }} onChange={(e) => setExtPermissions({ ...extPermissions, all: e.target.checked })} />}
                    label="Todos"
                    disabled={false}
                  />
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.mainMenu} onChange={(e) => setExtPermissions({ ...extPermissions, mainMenu: e.target.checked })} />}
                      label="Menu Principal"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.preferences} onChange={(e) => setExtPermissions({ ...extPermissions, preferences: e.target.checked })} />}
                      label="Preferências"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.notifications} onChange={(e) => setExtPermissions({ ...extPermissions, notifications: e.target.checked })} />}
                      label="Notificações"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.account} onChange={(e) => setExtPermissions({ ...extPermissions, account: e.target.checked })} />}
                      label="Conta"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.devices} onChange={(e) => setExtPermissions({ ...extPermissions, devices: e.target.checked })} />}
                      label="Dispositivos"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.geofence} onChange={(e) => setExtPermissions({ ...extPermissions, geofence: e.target.checked })} />}
                      label="Geocercas"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.groups} onChange={(e) => setExtPermissions({ ...extPermissions, groups: e.target.checked })} />}
                      label="Grupos"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.drivers} onChange={(e) => setExtPermissions({ ...extPermissions, drivers: e.target.checked })} />}
                      label="Motoristas"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.calendars} onChange={(e) => setExtPermissions({ ...extPermissions, calendars: e.target.checked })} />}
                      label="Calendários"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.calculatedAttr} onChange={(e) => setExtPermissions({ ...extPermissions, calculatedAttr: e.target.checked })} />}
                      label="Atr. Calculados"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.maintenance} onChange={(e) => setExtPermissions({ ...extPermissions, maintenance: e.target.checked })} />}
                      label="Manutenção"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.savedCommands} onChange={(e) => setExtPermissions({ ...extPermissions, savedCommands: e.target.checked })} />}
                      label="Comandos Salvos"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.server} onChange={(e) => setExtPermissions({ ...extPermissions, server: e.target.checked })} />}
                      label="Servidor"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.users} onChange={(e) => setExtPermissions({ ...extPermissions, users: e.target.checked })} />}
                      label="Usuários"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.editExtPermissions} onChange={(e) => setExtPermissions({ ...extPermissions, editExtPermissions: e.target.checked })} />}
                      label="Edit. Preferências"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.about} onChange={(e) => setExtPermissions({ ...extPermissions, about: e.target.checked })} />}
                      label="Sobre"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.logs} onChange={(e) => setExtPermissions({ ...extPermissions, logs: e.target.checked })} />}
                      label="Logs"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.billing} onChange={(e) => setExtPermissions({ ...extPermissions, billing: e.target.checked })} />}
                      label="Financeiro"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.crm} onChange={(e) => setExtPermissions({ ...extPermissions, crm: e.target.checked })} />}
                      label="MicroCRM"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.easyconf} onChange={(e) => setExtPermissions({ ...extPermissions, easyconf: e.target.checked })} />}
                      label="EasyConf"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.reports} onChange={(e) => setExtPermissions({ ...extPermissions, reports: e.target.checked })} />}
                      label="Relatórios"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.blockDevice} onChange={(e) => setExtPermissions({ ...extPermissions, blockDevice: e.target.checked })} />}
                      label="Bloquear"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.moreOptions} onChange={(e) => setExtPermissions({ ...extPermissions, moreOptions: e.target.checked })} />}
                      label="Mais Opções"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.replay} onChange={(e) => setExtPermissions({ ...extPermissions, replay: e.target.checked })} />}
                      label="Replay"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.editDevice} onChange={(e) => setExtPermissions({ ...extPermissions, editDevice: e.target.checked })} />}
                      label="Editar"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.removeDevice} onChange={(e) => setExtPermissions({ ...extPermissions, removeDevice: e.target.checked })} />}
                      label="Excluir"
                      disabled={false}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.shareDevice} onChange={(e) => setExtPermissions({ ...extPermissions, shareDevice: e.target.checked })} />}
                      label="Compartilhar"
                      disabled={false}
                    />
                    <FormControlLabel
                      style={{ width: '50%' }}
                      control={<Checkbox checked={extPermissions.dvr} onChange={(e) => setExtPermissions({ ...extPermissions, dvr: e.target.checked })} />}
                      label="DVR"
                      disabled={false}
                    />
                  </div>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Dados Cadastrais
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

              <TextField
                value={extraFields.fullName || ''}
                onChange={(event) => setExtraFields({ ...extraFields, fullName: event.target.value })}
                label="Nome Completo / Razão Social"
              />

              <TextField
                value={extraFields.cpfCnpj || ''}
                onChange={(event) => setExtraFields({ ...extraFields, cpfCnpj: event.target.value })}
                label="CPF/CNPJ"
              />

              <TextField
                value={extraFields.rgIe || ''}
                onChange={(event) => setExtraFields({ ...extraFields, rgIe: event.target.value })}
                label="RG/IE"
              />

              <TextField
                value={extraFields.cep || ''}
                onChange={(event) => { event.target.value.length >= 9 ? false : setExtraFields({ ...extraFields, cep: event.target.value.replace(/[^0-9]/g, '') }) }}
                onKeyUp={() => { handleCep() }}
                label="CEP"
                type="number"
              />

              <TextField
                value={extraFields.logradouro || ''}
                onChange={(event) => setExtraFields({ ...extraFields, logradouro: event.target.value })}
                label="Endereço"
              />

              <TextField
                value={extraFields.numero || ''}
                onChange={(event) => setExtraFields({ ...extraFields, numero: event.target.value })}
                label="Número"
              />

              <TextField
                value={extraFields.complemento || ''}
                onChange={(event) => setExtraFields({ ...extraFields, complemento: event.target.value })}
                label="Complemento"
              />

              <TextField
                value={extraFields.bairro || ''}
                onChange={(event) => setExtraFields({ ...extraFields, bairro: event.target.value })}
                label="Bairro"
              />

              <TextField
                value={extraFields.cidade || ''}
                onChange={(event) => setExtraFields({ ...extraFields, cidade: event.target.value })}
                label="Cidade"
              />

              <TextField
                value={extraFields.estado || ''}
                onChange={(event) => setExtraFields({ ...extraFields, estado: event.target.value })}
                label="Estado"
              />

              {item.attributes && (
                <FormControlLabel style={{ display: item.id !== currentUser.id ? 'block' : 'none' }}
                  control={<Checkbox checked={isReseller} onChange={() => handleReseller(!isReseller)} />}
                  label="Revenda"
                  disabled={!manager}
                />
              )}

            </AccordionDetails>
          </Accordion>

          <Accordion style={{ display: isReseller ? 'block' : 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Revenda
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                type="number"
                value={item.deviceLimit || 0}
                onChange={(e) => setItem({ ...item, deviceLimit: Number(e.target.value) })}
                label={t('userDeviceLimit')}
                disabled={!admin}
              />
              <TextField
                type="number"
                value={item.userLimit || 0}
                onChange={(e) => setItem({ ...item, userLimit: Number(e.target.value) })}
                label={t('userUserLimit')}
                disabled={!admin}
              />
              <TextField
                type="text"
                value={reseller.nomeFantasia || ''}
                onChange={(e) => setReseller({ ...reseller, nomeFantasia: e.target.value })}
                label="Nome Fantasia"
                disabled={!admin}
              />
              <TextField
                type="text"
                value={reseller.urlAcesso || ''}
                onChange={(e) => setReseller({ ...reseller, urlAcesso: e.target.value })}
                label="DNS"
                disabled={!admin}
                error={domainError}
              />
              <TextField
                type="text"
                value={reseller.urlLogotipo || ''}
                onChange={(e) => setReseller({ ...reseller, urlLogotipo: e.target.value })}
                label="Logotipo"
                disabled={!admin}
              />
              <TextField
                type="text"
                value={reseller.contato || ''}
                onChange={(e) => setReseller({ ...reseller, contato: e.target.value })}
                label="Contato"
                disabled={!admin}
              />

              <TextField
                type="text"
                value={reseller.email || ''}
                onChange={(e) => setReseller({ ...reseller, email: e.target.value })}
                label="Email"
                disabled={!admin}
              />

              <TextField
                type="text"
                value={reseller.plano || ''}
                onChange={(e) => setReseller({ ...reseller, plano: e.target.value })}
                label="Plano"
                disabled={true}
              />
              <Container>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="upload-image">
                    <Button variant="contained" component="span">
                      <PhotoCamera />
                    </Button>
                    <input
                      id="upload-image"
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleFileUpload}
                    />
                  </label>
                  <div>
                    {img !== null && <img onLoad={(event) => event.target.style.display = 'inline-block'} onError={(event) => event.target.style.display = 'none'} style={{ maxHeight: '60px' }} src={img} alt="Uploaded Image" />}
                  </div>
                </Stack>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedPreferences')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.phone || ''}
                onChange={(event) => setItem({ ...item, phone: event.target.value })}
                label={t('sharedPhone')}
              />
              <FormControl>
                <InputLabel>{t('mapDefault')}</InputLabel>
                <Select
                  label={t('mapDefault')}
                  value={item.map || 'locationIqStreets'}
                  onChange={(e) => setItem({ ...item, map: e.target.value })}
                >
                  {mapStyles.filter((style) => style.available).map((style) => (
                    <MenuItem key={style.id} value={style.id}>
                      <Typography component="span">{style.title}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>{t('settingsCoordinateFormat')}</InputLabel>
                <Select
                  label={t('settingsCoordinateFormat')}
                  value={item.coordinateFormat || 'dd'}
                  onChange={(event) => setItem({ ...item, coordinateFormat: event.target.value })}
                >
                  <MenuItem value="dd">{t('sharedDecimalDegrees')}</MenuItem>
                  <MenuItem value="ddm">{t('sharedDegreesDecimalMinutes')}</MenuItem>
                  <MenuItem value="dms">{t('sharedDegreesMinutesSeconds')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>{t('settingsSpeedUnit')}</InputLabel>
                <Select
                  label={t('settingsSpeedUnit')}
                  value={(item.attributes && item.attributes.speedUnit) || 'kn'}
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, speedUnit: e.target.value } })}
                >
                  <MenuItem value="kn">{t('sharedKn')}</MenuItem>
                  <MenuItem value="kmh">{t('sharedKmh')}</MenuItem>
                  <MenuItem value="mph">{t('sharedMph')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>{t('settingsDistanceUnit')}</InputLabel>
                <Select
                  label={t('settingsDistanceUnit')}
                  value={(item.attributes && item.attributes.distanceUnit) || 'km'}
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, distanceUnit: e.target.value } })}
                >
                  <MenuItem value="km">{t('sharedKm')}</MenuItem>
                  <MenuItem value="mi">{t('sharedMi')}</MenuItem>
                  <MenuItem value="nmi">{t('sharedNmi')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>{t('settingsAltitudeUnit')}</InputLabel>
                <Select
                  label={t('settingsAltitudeUnit')}
                  value={(item.attributes && item.attributes.altitudeUnit) || 'm'}
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, altitudeUnit: e.target.value } })}
                >
                  <MenuItem value="m">{t('sharedMeters')}</MenuItem>
                  <MenuItem value="ft">{t('sharedFeet')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>{t('settingsVolumeUnit')}</InputLabel>
                <Select
                  label={t('settingsVolumeUnit')}
                  value={(item.attributes && item.attributes.volumeUnit) || 'ltr'}
                  onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, volumeUnit: e.target.value } })}
                >
                  <MenuItem value="ltr">{t('sharedLiter')}</MenuItem>
                  <MenuItem value="usGal">{t('sharedUsGallon')}</MenuItem>
                  <MenuItem value="impGal">{t('sharedImpGallon')}</MenuItem>
                </Select>
              </FormControl>
              <SelectField
                value={(item.attributes && item.attributes.timezone) || ''}
                emptyValue=""
                onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, timezone: e.target.value } })}
                endpoint="/api/server/timezones"
                keyGetter={(it) => it}
                titleGetter={(it) => it}
                label={t('sharedTimezone')}
              />
              <TextField
                value={item.poiLayer || ''}
                onChange={(event) => setItem({ ...item, poiLayer: event.target.value })}
                label={t('mapPoiLayer')}
              />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={item.twelveHourFormat} onChange={(event) => setItem({ ...item, twelveHourFormat: event.target.checked })} />}
                  label={t('settingsTwelveHourFormat')}
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedLocation')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                type="number"
                value={item.latitude || 0}
                onChange={(event) => setItem({ ...item, latitude: Number(event.target.value) })}
                label={t('positionLatitude')}
              />
              <TextField
                type="number"
                value={item.longitude || 0}
                onChange={(event) => setItem({ ...item, longitude: Number(event.target.value) })}
                label={t('positionLongitude')}
              />
              <TextField
                type="number"
                value={item.zoom || 0}
                onChange={(event) => setItem({ ...item, zoom: Number(event.target.value) })}
                label={t('serverZoom')}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  const { lng, lat } = map.getCenter();
                  setItem({
                    ...item,
                    latitude: Number(lat.toFixed(6)),
                    longitude: Number(lng.toFixed(6)),
                    zoom: Number(map.getZoom().toFixed(1)),
                  });
                }}
              >
                {t('mapCurrentLocation')}
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedPermissions')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                label={t('userExpirationTime')}
                type="date"
                value={(item.expirationTime && moment(item.expirationTime).locale('en').format(moment.HTML5_FMT.DATE)) || '2099-01-01'}
                onChange={(e) => setItem({ ...item, expirationTime: moment(e.target.value, moment.HTML5_FMT.DATE).locale('en').format() })}
                disabled={!manager}
              />
              <TextField
                type="number"
                value={item.deviceLimit || 0}
                onChange={(e) => setItem({ ...item, deviceLimit: Number(e.target.value) })}
                label={t('userDeviceLimit')}
                disabled={!admin}
              />
              <TextField
                type="number"
                value={item.userLimit || 0}
                onChange={(e) => setItem({ ...item, userLimit: Number(e.target.value) })}
                label={t('userUserLimit')}
                disabled={!admin}
              />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={item.disabled} onChange={(e) => setItem({ ...item, disabled: e.target.checked })} />}
                  label={t('sharedDisabled')}
                  disabled={!manager}
                />
                <FormControlLabel
                  control={<Checkbox checked={item.administrator} onChange={(e) => setItem({ ...item, administrator: e.target.checked })} />}
                  label={t('userAdmin')}
                  disabled={!admin}
                />
                <FormControlLabel
                  control={<Checkbox checked={item.readonly} onChange={(e) => setItem({ ...item, readonly: e.target.checked })} />}
                  label={t('serverReadonly')}
                  disabled={!manager}
                />
                <FormControlLabel
                  control={<Checkbox checked={item.deviceReadonly} onChange={(e) => setItem({ ...item, deviceReadonly: e.target.checked })} />}
                  label={t('userDeviceReadonly')}
                  disabled={!manager}
                />
                <FormControlLabel
                  control={<Checkbox checked={item.limitCommands} onChange={(e) => setItem({ ...item, limitCommands: e.target.checked })} />}
                  label={t('userLimitCommands')}
                  disabled={!manager}
                />
                <FormControlLabel
                  control={<Checkbox checked={item.disableReports} onChange={(e) => setItem({ ...item, disableReports: e.target.checked })} />}
                  label={t('userDisableReports')}
                  disabled={!manager}
                />
                <FormControlLabel
                  control={<Checkbox checked={item.fixedEmail} onChange={(e) => setItem({ ...item, fixedEmail: e.target.checked })} />}
                  label={t('userFixedEmail')}
                  disabled={!manager}
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <EditAttributesAccordion
            attribute={attribute}
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{ ...commonUserAttributes, ...userAttributes }}
            focusAttribute={attribute}
          />
          {registrationEnabled && item.id === currentUser.id && !manager && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" color="error">
                  {t('userDeleteAccount')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  value={deleteEmail}
                  onChange={(event) => setDeleteEmail(event.target.value)}
                  label={t('userEmail')}
                  error={deleteFailed}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDelete}
                  startIcon={<DeleteForeverIcon />}
                >
                  {t('userDeleteAccount')}
                </Button>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      )}
    </EditItemView>
  );
};

export default UserPage;
