import React from 'react';
import {
  Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimelineIcon from '@mui/icons-material/Timeline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BarChartIcon from '@mui/icons-material/BarChart';
import RouteIcon from '@mui/icons-material/Route';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import SpeedIcon from '@mui/icons-material/Speed';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useAdministrator, useRestriction } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const CustomButtonMenu = ({ icon, text, link }) => {
  const navigate = useNavigate();
  return (
    <IconButton
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '10px',
        border: '1px solid lightgray',
        width: '65px',
        height: '65px',
        cursor: 'pointer',
        color: 'gray',
        marginLeft: '5px',
        marginRight: '5px',
        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
        // backgroundColor: 'red',
      }}
      onClick={() => { navigate(link); }}
    >
      {icon}
      <Typography variant="caption" noWrap color="textSecondary" style={{ marginTop: '5px', maxWidth: '45px', fontSize: '9px' }}>{text}</Typography>
    </IconButton>
  );
};

const ReportsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const admin = useAdministrator();
  const readonly = useRestriction('readonly');

  return (
    <>
      <Typography variant="body1" style={{ marginLeft: '20px', marginTop: '10px', width: '100%', color: 'gray', fontSize: '13px' }}>Geral</Typography>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        padding: '10px',
        // backgroundColor: 'red',
      }}
      >
        <CustomButtonMenu icon={<LocationOnIcon />} text="Monitoramento" link="/?" />

      </div>

      <Typography variant="body1" style={{ marginLeft: '20px', marginTop: '10px', width: '100%', color: 'gray', fontSize: '13px' }}>Usuários</Typography>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        padding: '10px',
        // backgroundColor: 'red',
      }}
      >
        <CustomButtonMenu icon={<SummarizeIcon />} text="Completo" link="/reports/advanced/usersFull" />
        <CustomButtonMenu icon={<ContactPageIcon />} text="Sintético" link="/reports/advanced/usersSummary" />

      </div>

      <Typography variant="body1" style={{ marginLeft: '20px', marginTop: '10px', width: '100%', color: 'gray', fontSize: '13px' }}>Objetos</Typography>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        padding: '10px',
        // backgroundColor: 'red',
      }}
      >
        <CustomButtonMenu icon={<DescriptionIcon />} text="Completo" link="/reports/advanced/devicesFull" />
        <CustomButtonMenu icon={<DirectionsCarIcon />} text="Sintético" link="/reports/advanced/devicesSummary" />

        <CustomButtonMenu icon={<AccessAlarmIcon />} text="Últ. envio" link="/reports/advanced/devicesLastUpdate" />
        <CustomButtonMenu icon={<SpeedIcon />} text="Velocidade" link="/reports/advanced/devicesSpeed" />

      </div>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        padding: '10px',
        // backgroundColor: 'red',
      }}
      >
        <CustomButtonMenu icon={<MoveUpIcon />} text="Migração" link="/reports/advanced/devicesMigration" />

      </div>

      <Typography variant="body1" style={{ marginLeft: '20px', marginTop: '10px', width: '100%', color: 'gray', fontSize: '13px' }}>Padrão</Typography>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        padding: '10px',
        // backgroundColor: 'red',
      }}
      >
        <CustomButtonMenu icon={<StarIcon />} text={t('reportCombined')} link="/reports/combined" />
        <CustomButtonMenu icon={<TimelineIcon />} text={t('reportRoute')} link="/reports/route" />
        <CustomButtonMenu icon={<NotificationsActiveIcon />} text={t('reportEvents')} link="/reports/event" />
        <CustomButtonMenu icon={<PlayCircleFilledIcon />} text={t('reportTrips')} link="/reports/trip" />

      </div>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        padding: '10px',
        // backgroundColor: 'red',
      }}
      >
        <CustomButtonMenu icon={<PauseCircleFilledIcon />} text={t('reportStops')} link="/reports/stop" />
        <CustomButtonMenu icon={<FormatListBulletedIcon />} text={t('reportSummary')} link="/reports/summary" />
        <CustomButtonMenu icon={<TrendingUpIcon />} text={t('reportChart')} link="/reports/chart" />
        <CustomButtonMenu icon={<RouteIcon />} text={t('reportReplay')} link="/reports/replay" />

      </div>

      <List style={{ display: 'none' }}>
        <MenuItem
          title="Monitoramento"
          link="/?"
          icon={<LocationOnIcon />}
        />
        <Typography variant="body1" style={{ marginLeft: '20px', marginTop: '10px' }}>Objetos</Typography>
        <MenuItem
          style={{ marginLeft: '20px' }}
          title="Detalhado"
          link="/reports/advanced/devicesFull"
          icon={<DescriptionIcon />}
          selected={location.pathname === '/reports/advanced/devicesFull'}
        />
        <MenuItem
          title="Sintético"
          link="/reports/advanced/devicesSummary"
          icon={<DirectionsCarIcon />}
          selected={location.pathname === '/reports/advanced/devicesSummary'}
        />

        <Typography variant="body1" style={{ marginLeft: '20px', marginTop: '10px' }}>Usuários</Typography>
        <MenuItem
          style={{ marginLeft: '20px' }}
          title="Detalhado"
          link="/reports/advanced/usersFull"
          icon={<SummarizeIcon />}
          selected={location.pathname === '/reports/advanced/usersFull'}
        />
        <MenuItem
          title="Sintético"
          link="/reports/advanced/usersSummary"
          icon={<ContactPageIcon />}
          selected={location.pathname === '/reports/advanced/usersSummary'}
        />

        <Typography variant="body1" style={{ marginLeft: '20px', marginTop: '10px' }}>Padrão</Typography>
        <MenuItem
          title={t('reportCombined')}
          link="/reports/combined"
          icon={<StarIcon />}
          selected={location.pathname === '/reports/combined'}
        />
        <MenuItem
          title={t('reportRoute')}
          link="/reports/route"
          icon={<TimelineIcon />}
          selected={location.pathname === '/reports/route'}
        />
        <MenuItem
          title={t('reportEvents')}
          link="/reports/event"
          icon={<NotificationsActiveIcon />}
          selected={location.pathname === '/reports/event'}
        />
        <MenuItem
          title={t('reportTrips')}
          link="/reports/trip"
          icon={<PlayCircleFilledIcon />}
          selected={location.pathname === '/reports/trip'}
        />
        <MenuItem
          title={t('reportStops')}
          link="/reports/stop"
          icon={<PauseCircleFilledIcon />}
          selected={location.pathname === '/reports/stop'}
        />
        <MenuItem
          title={t('reportSummary')}
          link="/reports/summary"
          icon={<FormatListBulletedIcon />}
          selected={location.pathname === '/reports/summary'}
        />
        <MenuItem
          title={t('reportChart')}
          link="/reports/chart"
          icon={<TrendingUpIcon />}
          selected={location.pathname === '/reports/chart'}
        />
        <MenuItem
          title={t('reportReplay')}
          link="/replay"
          icon={<RouteIcon />}
        />
      </List>
      {(admin || !readonly) && (
        <>
          <Divider />
          <List>
            <MenuItem
              title={t('reportScheduled')}
              link="/reports/scheduled"
              icon={<EventRepeatIcon />}
            />
            {admin && (
              <MenuItem
                title={t('statisticsTitle')}
                link="/reports/statistics"
                icon={<BarChartIcon />}
                selected={location.pathname === '/reports/statistics'}
              />
            )}
          </List>
        </>
      )}
    </>
  );
};

export default ReportsMenu;
