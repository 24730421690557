import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton, Tooltip, Avatar, ListItemAvatar, ListItemText, ListItemButton, Typography
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ErrorIcon from '@mui/icons-material/Error';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import moment from 'moment';
import { devicesActions } from '../store';
import {
  formatAlarm, formatBoolean, formatPercentage, formatStatus, getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { useAttributePreference } from '../common/util/preferences';
import { Lock, LockOpen } from '@mui/icons-material';
import ContextMenu from './ContextMenu';
import CarrierImage from './CarrierImage';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '17px',
    height: '17px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  orange: {
    color: '#FC6A03',
  },
}));


const getIconPath = (device) => {
  // const url = src={`${process.env.REACT_APP_MEDIA_SERVER}/get/customIcons/${iconItem}`}
  const url = `${process.env.REACT_APP_MEDIA_SERVER}/get/customIcons`;
  if (typeof device.attributes.customIcon === 'undefined') {
    return `${url}/image310.png`;
  }

  return `${url}/${device.attributes.customIcon}`;
};

const diffDates = (seed) => {
  if (seed === null) {
    return { time: "NR", color: 'red' };
  }
  // const pad = function(n){ return n < 10 ? '0' + n : n; };
  const millisec = new Date() - new Date(seed);
  var seconds = (millisec / 1000).toFixed(0);
  var minutes = Math.floor(seconds / 60);
  var hours = "";
  var color = "";

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    hours = (hours >= 10) ? hours : "0" + hours;
    minutes = minutes - (hours * 60);
    minutes = (minutes >= 10) ? minutes : "0" + minutes;
  }

  seconds = Math.floor(seconds % 60);
  seconds = (seconds >= 10) ? seconds : "0" + seconds;
  if (hours >= 24) {
    color = "purple";
  } else if (hours >= 12) {
    color = "darkred";
  } else if (hours >= 6) {
    color = "red";
  } else if (hours >= 3) {
    color = "#ff5c33";
  } else if (minutes >= 30 || hours >= 1) {
    color = "rgb(255, 160, 0)";
  } else {
    color = "green";
  }

  if (hours != "") {
    if (hours >= 24)
      return { time: Math.floor(hours / 24) + ' D(s)', color: color };
    return { time: hours + ":" + minutes + ":" + seconds, color: color };
  }
  return { time: minutes + ":" + seconds, color: color };
}

const isBlocked = (position) => {
  return Boolean(position.attributes.blocked || position.attributes.out1 || position.attributes.output || (position.attributes.event === 'jt'));
}

const DeviceRow = ({ data, index, style }) => {
  // console.log(data, index);
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const [isHover, setIsHover] = useState(false);

  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');

  const secondaryText = () => {
    let status;
    if (item.status === 'online' || !item.lastUpdate) {
      status = formatStatus(item.status, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
    }
    return (
      <>
        {deviceSecondary && item[deviceSecondary] && `${item[deviceSecondary]} • `}
        <span className={classes[getStatusColor(item.status)]}>{status}</span>
      </>
    );
  };

  const handleBlockWait = (position) => {
    try {
      const lastSend = JSON.parse(localStorage.getItem(position.deviceId));
      const diff = (new Date().getTime() - new Date(lastSend.date).getTime()) / 1000;
      if (diff <= 25 || isBlocked(position) === lastSend.lastStatus) {
        return true;
      }
    } catch {
      // console.log('Nunca foi bloqueado...');
      return false;
    }
    return false;
  };

  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={() => dispatch(devicesActions.selectId(item.id))} style={{ ...style, backgroundColor: isHover ? '#fafafa' : '', cursor: 'pointer', padding: '5px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '13px', height: '45px', width: '100%', borderBottom: '1px solid #F8F8F8' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', padding: '5px' }}>
        <img src={getIconPath(item)} height={35} alt="" />
      </div>
      <div style={{ width: '55%', noWrap: true, marginLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', noWrap: true, marginLeft: '10px', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography style={{ width: '140px', fontWeight: '500', fontSize: '12px' }} noWrap variant="body1" color="textSecondary">
            {item[devicePrimary]}
          </Typography>
          <Tooltip title={position && (position.address)}>
            <Typography style={{ maxWidth: '140px' }} noWrap variant="caption" color="textSecondary">
              {position && (position.address)}
            </Typography>
          </Tooltip>
        </div>
        <span style={{ minWidth: '50px', noWrap: true, backgroundColor: diffDates(item.lastUpdate).color, color: 'white', fontSize: '10px', borderRadius: '15px', paddingLeft: '5px', paddingRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {diffDates(item.lastUpdate).time}
        </span>
      </div>
      <div style={{ display: 'flex', width: '40%', alignItems: 'center', justifyContent: 'right' }}>

        {position && (
          <>
            {position.network && (
              <CarrierImage position={position} />
            )}
            {position.attributes.hasOwnProperty('alarm') && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <ErrorIcon style={{ fontSize: '17px', margin: '1px' }} className={classes.negative} />
              </Tooltip>
            )}
            {position.hasOwnProperty('speed') && (
              <Tooltip title={`${t('positionMotion')}: ${formatBoolean(parseInt(position.speed) > 0, t)}`}>
                {parseInt(position.speed) > 0 ? (
                  <PlayArrowIcon style={{ fontSize: '17px', margin: '1px' }} className={classes.positive} />
                ) : (
                  <PlayArrowIcon style={{ fontSize: '17px', margin: '1px' }} className={classes.neutral} />
                )}
              </Tooltip>
            )}

            {/*(!position.attributes.blocked && !position.attributes.out1 && !position.attributes.output) && (
              <Tooltip title={`${t('positionBlocked')}: ${formatBoolean(isBlocked(position), t)}`}>
                <CircularProgress style={{ width: '14px', height: '14px' }} className={[classes.icones, classes.orange]} />
              </Tooltip>
            )*/}

            {handleBlockWait(position) && (
              <Tooltip title={`${t('positionBlocked')}: ${formatBoolean(isBlocked(position), t)}`}>
                <CircularProgress style={{ width: '14px', height: '14px' }} className={[classes.icones, classes.orange]} />
              </Tooltip>
            )}

            {!handleBlockWait(position) && (
              <Tooltip title={`${t('positionBlocked')}: ${formatBoolean(isBlocked(position), t)}`}>
                {isBlocked(position) ? (
                  <Lock style={{ fontSize: '15px', margin: '1px' }} className={classes.negative} />
                ) : (
                  <LockOpen style={{ fontSize: '15px', margin: '1px' }} className={classes.positive} />
                )}
              </Tooltip>
            )}

            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                {position.attributes.ignition ? (
                  <KeyIcon style={{ fontSize: '17px', margin: '1px' }} className={classes.positive} />
                ) : (
                  <KeyIcon style={{ fontSize: '17px', margin: '1px' }} className={classes.neutral} />
                )}
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('batteryLevel') && (
              <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                {position.attributes.batteryLevel > 70 ? (
                  position.attributes.charge
                    ? (<BatteryChargingFullIcon style={{ fontSize: '17px', margin: '1px' }} className={classes.positive} />)
                    : (<BatteryFullIcon style={{ fontSize: '17px', margin: '1px' }} className={classes.positive} />)
                ) : position.attributes.batteryLevel > 30 ? (
                  position.attributes.charge
                    ? (<BatteryCharging60Icon style={{ fontSize: '17px', margin: '1px' }} className={classes.medium} />)
                    : (<Battery60Icon style={{ fontSize: '17px', margin: '1px' }} className={classes.medium} />)
                ) : (
                  position.attributes.charge
                    ? (<BatteryCharging20Icon style={{ fontSize: '17px', margin: '1px' }} className={classes.negative} />)
                    : (<Battery20Icon style={{ fontSize: '17px', margin: '1px' }} className={classes.negative} />)
                )}
              </Tooltip>
            )}
          </>
        )}
        <ContextMenu deviceId={item.id} />
      </div>
    </div>
  );
  /*
  
  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.selectId(item.id))}
        disabled={!admin && item.disabled}
        style={{ padding: 0, margin: 0 }}
      >
        <ListItemAvatar>
          <Avatar style={{ maxHeight: '25px', maxWidth: '25px' }}>
            <img className={classes.icon} src={mapIcons[mapIconKey(item.category)]} alt="" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item[devicePrimary]}
          primaryTypographyProps={{ noWrap: true, fontSize: '13px' }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true, fontSize: '13px' }}
        />
        {position && (
          <>
            {position.attributes.hasOwnProperty('alarm') && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="small">
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small">
                  {position.attributes.ignition ? (
                    <EngineIcon width={20} height={20} className={classes.positive} />
                  ) : (
                    <EngineIcon width={20} height={20} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small">
                  {position.attributes.ignition ? (
                    <EngineIcon width={20} height={20} className={classes.positive} />
                  ) : (
                    <EngineIcon width={20} height={20} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small">
                  {position.attributes.ignition ? (
                    <EngineIcon width={20} height={20} className={classes.positive} />
                  ) : (
                    <EngineIcon width={20} height={20} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('batteryLevel') && (
              <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                <IconButton size="small">
                  {position.attributes.batteryLevel > 70 ? (
                    position.attributes.charge
                      ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                      : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
                  ) : position.attributes.batteryLevel > 30 ? (
                    position.attributes.charge
                      ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                      : (<Battery60Icon fontSize="small" className={classes.medium} />)
                  ) : (
                    position.attributes.charge
                      ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                      : (<Battery20Icon fontSize="small" className={classes.negative} />)
                  )}
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </ListItemButton>
    </div>
  );
  */
};

export default DeviceRow;
