import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Container,
  Stack,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneArea } from 'react-mui-dropzone';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import SelectField from '../common/components/SelectField';
import deviceCategories from '../common/util/deviceCategories';
import { useTranslation } from '../common/components/LocalizationProvider';
import useDeviceAttributes from '../common/attributes/useDeviceAttributes';
import { useAdministrator } from '../common/util/permissions';
import SettingsMenu from './components/SettingsMenu';
import useCommonDeviceAttributes from '../common/attributes/useCommonDeviceAttributes';
import { useCatch } from '../reactHelper';
import EasyConfModal from '../main/EasyConfModal';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const admin = useAdministrator();

  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);

  const [item, setItem] = useState();
  const [openEasyConf, setOpenEasyConf] = useState(false);

  const [dvrUrl, setDvrUrl] = useState(null);
  const handleDvrUrl = (e) => {
    setDvrUrl(e.target.value);
    item.attributes.dvrUrl = e.target.value;
  };

  const [extraFields, setExtraFields] = useState({
    placa: '',
    corPredominante: '',
    marcaModelo: '',
    especieTipo: '',
    anoFabMod: '',
    combustível: '',
    categoria: '',
    potenciacilidrada: '',
    carroceria: '',
    numMotor: '',
    chassi: '',
    placaAnterior: '',
    observacoes: '',
    precoLitro: 5.5,
    kmLitro: 10,
  });

  const [iconList, setIconList] = useState();
  const [customIcon, setCustomIcon] = useState(null);

  const handleIcon = (selectedIcon) => {
    // alert(selectedIcon);
    setCustomIcon(selectedIcon);
    item.attributes.customIcon = selectedIcon;
    console.log(customIcon);
  };

  const handleEasyConf = () => {
    setOpenEasyConf(true);
  };

  const getIconList = () => {
    console.log('');
    fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/icons`, { method: 'GET' })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.files);
        setIconList(res.files);
      });
  };

  const handleFileUpload = async (element) => {
    const data = new FormData();
    data.append('file', element.target.files[0]);

    fetch(`${process.env.REACT_APP_MEDIA_SERVER}/icons/upload`, {
      method: 'POST',
      body: data,
      // mode: 'no-cors',
    }).then((response) => response.json())
      .then(async (response) => {
        // setReseller({ ...reseller, urlLogotipo: response.fileDetails.filename });
        getIconList();
        console.log(response);
        handleIcon(response.fileDetails.filename);
        // console.log(await validateImage());
        // item.attributes.reseller = JSON.stringify(reseller);
        // console.log("Response: ", response)
      });
  };

  const handleFiles = useCatch(async (files) => {
    if (files.length > 0) {
      const response = await fetch(`/api/devices/${item.id}/image`, {
        method: 'POST',
        body: files[0],
      });
      if (response.ok) {
        setItem({ ...item, attributes: { ...item.attributes, deviceImage: await response.text() } });
      } else {
        throw Error(await response.text());
      }
    }
  });

  useEffect(() => {
    try {
      setDvrUrl(item.attributes.dvrUrl);
    } catch {
      console.log('DVR URL não pronto');
    }
    try {
      setCustomIcon(item.attributes.customIcon);
    } catch {
      console.log('Ícone não pronto');
    }
  }, [item]);

  useEffect(() => {
    getIconList();
  }, []);

  useEffect(() => {
    try {
      item.attributes.extraFields = JSON.stringify(extraFields);
    } catch {
      // console.log('Não Pronto');
    }

    console.log(extraFields);
  }, [extraFields]);

  useEffect(() => {
    try {
      setExtraFields(JSON.parse(item.attributes.extraFields));
    } catch {
      // console.log('Não Pronto');
    }
  }, [item]);

  const validate = () => item && item.name && item.uniqueId;

  return (
    <EditItemView
      endpoint="devices"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'sharedDevice']}
    >
      <EasyConfModal openEasyConf={openEasyConf} setOpenEasyConf={setOpenEasyConf} />
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <TextField
                value={item.uniqueId || ''}
                onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                label={t('deviceIdentifier')}
                helperText={t('deviceIdentifierHelp')}
              />

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
                <Button onClick={handleEasyConf}>
                  <AutoFixNormalIcon fontSize="small" />
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Dados do Veículo
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

              <TextField
                value={extraFields.precoLitro || ''}
                onChange={(event) => setExtraFields({ ...extraFields, precoLitro: event.target.value })}
                label="Preço por Litro"
              />

              <TextField
                value={extraFields.kmLitro || ''}
                onChange={(event) => setExtraFields({ ...extraFields, kmLitro: event.target.value })}
                label="KMs por Litro"
              />

              <TextField
                value={extraFields.placa || ''}
                onChange={(event) => setExtraFields({ ...extraFields, placa: event.target.value })}
                label="Placa"
              />

              <TextField
                value={extraFields.numMotor || ''}
                onChange={(event) => setExtraFields({ ...extraFields, numMotor: event.target.value })}
                label="Número do Motor"
              />

              <TextField
                value={extraFields.chassi || ''}
                onChange={(event) => setExtraFields({ ...extraFields, chassi: event.target.value })}
                label="Chassi"
              />

              <TextField
                value={extraFields.placaAnterior || ''}
                onChange={(event) => setExtraFields({ ...extraFields, placaAnterior: event.target.value })}
                label="Placa Anterior"
              />

              <TextField
                value={extraFields.cidade || ''}
                onChange={(event) => setExtraFields({ ...extraFields, cidade: event.target.value })}
                label="Cidade"
              />

              <TextField
                value={extraFields.uf || ''}
                onChange={(event) => setExtraFields({ ...extraFields, uf: event.target.value })}
                label="Estado"
              />

              <TextField
                value={extraFields.corPredominante || ''}
                onChange={(event) => setExtraFields({ ...extraFields, corPredominante: event.target.value })}
                label="Cor Predominante"
              />

              <TextField
                value={extraFields.marcaModelo || ''}
                onChange={(event) => setExtraFields({ ...extraFields, marcaModelo: event.target.value })}
                label="Marca/Modelo"
              />

              <TextField
                value={extraFields.especieTipo || ''}
                onChange={(event) => setExtraFields({ ...extraFields, especieTipo: event.target.value })}
                label="Espécie/Tipo"
              />

              <TextField
                value={extraFields.anoFabMod || ''}
                onChange={(event) => setExtraFields({ ...extraFields, anoFabMod: event.target.value })}
                label="Ano Fabricação/Modelo"
              />

              <TextField
                value={extraFields.combustível || ''}
                onChange={(event) => setExtraFields({ ...extraFields, combustível: event.target.value })}
                label="Combustível"
              />

              <TextField
                value={extraFields.categoria || ''}
                onChange={(event) => setExtraFields({ ...extraFields, categoria: event.target.value })}
                label="Categoria"
              />

              <TextField
                value={extraFields.potenciacilidrada || ''}
                onChange={(event) => setExtraFields({ ...extraFields, potenciacilidrada: event.target.value })}
                label="Potência/Cilindrada"
              />

              <TextField
                value={extraFields.carroceria || ''}
                onChange={(event) => setExtraFields({ ...extraFields, carroceria: event.target.value })}
                label="Carroceria"
              />

              <TextField
                value={extraFields.observacoes || ''}
                onChange={(event) => setExtraFields({ ...extraFields, observacoes: event.target.value })}
                label="Observações"
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Ícones
              </Typography>
            </AccordionSummary>
            {iconList && (
              <AccordionDetails className={classes.details}>
                <ImageList sx={{ width: '100%', height: 250 }} cols={3} rowHeight={96}>
                  {iconList.map((iconItem) => (
                    <ImageListItem gap={4} key={iconItem} onClick={() => { handleIcon(iconItem); }}>
                      <img
                        src={`${process.env.REACT_APP_MEDIA_SERVER}/get/customIcons/${iconItem}`}
                        srcSet={`${process.env.REACT_APP_MEDIA_SERVER}/get/customIcons/${iconItem}`}
                        alt={`${process.env.REACT_APP_MEDIA_SERVER}/get/customIcons/${iconItem}`}
                        style={{ width: '30px', marginTop: '15px', margin: '30px', border: iconItem === customIcon ? '2px solid blue' : '' }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>

                <Container>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="upload-image">
                      <Button variant="contained" component="span">
                        <PhotoCamera />
                      </Button>
                      <input
                        id="upload-image"
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleFileUpload}
                      />
                    </label>
                  </Stack>
                </Container>

              </AccordionDetails>

            )}
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                DVR / CFTV
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={dvrUrl || ''}
                onChange={handleDvrUrl}
                label="Streaming"
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedExtra')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <SelectField
                value={item.groupId || 0}
                onChange={(event) => setItem({ ...item, groupId: Number(event.target.value) })}
                endpoint="/api/groups"
                label={t('groupParent')}
              />
              <TextField
                value={item.phone || ''}
                onChange={(event) => setItem({ ...item, phone: event.target.value })}
                label={t('sharedPhone')}
              />
              <TextField
                value={item.model || ''}
                onChange={(event) => setItem({ ...item, model: event.target.value })}
                label={t('deviceModel')}
              />
              <TextField
                value={item.contact || ''}
                onChange={(event) => setItem({ ...item, contact: event.target.value })}
                label={t('deviceContact')}
              />
              <SelectField
                value={item.category || 'default'}
                emptyValue={null}
                onChange={(event) => setItem({ ...item, category: event.target.value })}
                data={deviceCategories.map((category) => ({
                  id: category,
                  name: t(`category${category.replace(/^\w/, (c) => c.toUpperCase())}`),
                }))}
                label={t('deviceCategory')}
              />
              <TextField
                label={t('userExpirationTime')}
                type="date"
                value={(item.expirationTime && moment(item.expirationTime).locale('en').format(moment.HTML5_FMT.DATE)) || '2099-01-01'}
                onChange={(e) => setItem({ ...item, expirationTime: moment(e.target.value, moment.HTML5_FMT.DATE).locale('en').format() })}
                disabled={!admin}
              />
              <FormControlLabel
                control={<Checkbox checked={item.disabled} onChange={(event) => setItem({ ...item, disabled: event.target.checked })} />}
                label={t('sharedDisabled')}
                disabled={!admin}
              />
            </AccordionDetails>
          </Accordion>
          {item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('attributeDeviceImage')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <DropzoneArea
                  dropzoneText={t('sharedDropzoneText')}
                  acceptedFiles={['image/*']}
                  filesLimit={1}
                  onChange={handleFiles}
                  showAlerts={false}
                />
              </AccordionDetails>
            </Accordion>
          )}
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{ ...commonDeviceAttributes, ...deviceAttributes }}
          />
        </>
      )}
    </EditItemView>
  );
};

export default DevicePage;
