import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
// import CachedIcon from '@mui/icons-material/Cached';
import { IconButton, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '350px',
  width: '90%',
  height: '80%',
  // overflowY: 'scroll',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  padding: '20px',
  boxShadow: 24,
  borderRadius: '25px',
};

const useStyles = makeStyles((theme) => ({
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },

}));

const LogsModal = ({ isAdmin, openLogs, setOpenLogs }) => {
  const classes = useStyles();
  const [logs, setLogs] = useState('');
  const [seed, setSeed] = useState('')
  // alert(process.env.REACT_APP_MEDIA_SERVER + '/get/logs/' + seed +'' +isAdmin);
  const fetchLogs = () => {
    fetch(process.env.REACT_APP_MEDIA_SERVER + '/get/logs/' + isAdmin +'/' +seed, {
      method: 'GET',
      // headers: { 'Content-Type': 'application/json' },
      // body: JSON.stringify(payload),
    }).then((response) => response.json())
      .then((response) => {
        console.log(JSON.parse(JSON.stringify(response)));
        setLogs(response);
      });
  };

  useEffect(() => {
    if (openLogs)
      fetchLogs();
  }, [openLogs]);
  return (

    <div>
      <Modal
        open={openLogs}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              Logs
            </Typography>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '5px',
            }}
            >
              <TextField
                type="text"
                value={seed}
                onChange={(e) => { setSeed(e.target.value); fetchLogs(); }}
                label="Filtrar"
                style={{ display: isAdmin ? 'block' : 'none' }}
              />
              <IconButton
                size="small"
                onClick={() => { fetchLogs() }}
              >
                <ReplayIcon fontSize="small" className={classes.mediaButton} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => { setOpenLogs(false); }}
              >
                <CloseIcon fontSize="small" className={classes.mediaButton} />
              </IconButton>
            </div>
          </div>
          <textarea style={{ width: '100%', height: '90%', fontSize: '11px' }}
            value={logs}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default LogsModal;
