import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '350px',
  // overflowY: 'scroll',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  padding: '20px',
  boxShadow: 24,
  borderRadius: '25px',
};

const useStyles = makeStyles((theme) => ({
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  dashboardLogo: {
    maxHeight: '120px',
    maxWidth: '200px',
    margin: '20px',
    marginTop: '25px',
    marginBottom: '35px',

  },

}));

const AboutModal = ({ openAbout, setOpenAbout }) => {
  const classes = useStyles();
  const [revenda, setRevenda] = useState('');
  // alert(process.env.REACT_APP_MEDIA_SERVER + '/get/logs/' + seed +'' +isAdmin);
  const fetchAbout = () => {
    setRevenda(JSON.parse(localStorage.getItem('revenda')));
  };

  useEffect(() => {
    if (openAbout)
      fetchAbout();
  }, [openAbout]);
  return (

    <div>
      <Modal
        open={openAbout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{
            minWidth: '350px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              Sobre
            </Typography>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '5px',
            }}
            >
              <IconButton
                size="small"
                onClick={() => { setOpenAbout(false); }}
              >
                <CloseIcon fontSize="small" className={classes.mediaButton} />
              </IconButton>
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '5px',
          }}
          >
            <img alt="" className={classes.dashboardLogo} src={`${process.env.REACT_APP_MEDIA_SERVER}/get/${revenda.urlLogotipo}`} />
            <Typography variant="head1" color="textSecondary">
              {revenda.nomeFantasia}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {revenda.contato}
            </Typography>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            marginTop: '25px',
            width: '100%',
          }}
          >
            <Typography style={{ fontSize: '13px' }} variant="" color="textSecondary">
              Versão Backend: 5.8 <br />
              Versão Frontend: 2.7 <br />
              Versão MediaServer: 1.0 <br />
              <a href="https://www.freepik.com/free-vector/white-cars-top-view-realistic-set-isolated_6845832.htm#query=bus%20top%20view&position=2&from_view=keyword&track=ais">Image by macrovector</a> on Freepik<br />
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AboutModal;
