import React, {
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import BugReportIcon from '@mui/icons-material/BugReport';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoIcon from '@mui/icons-material/Info';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PaidIcon from '@mui/icons-material/Paid';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import SupportIcon from '@mui/icons-material/Support';
import { Person } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useTranslation } from '../common/components/LocalizationProvider';
import { nativePostMessage } from '../common/components/NativeInterface';
import { sessionActions } from '../store';
import LogsModal from './LogsModal';
// import CrmModal from './CrmModal';
import AboutModal from './AboutModal';
import EasyConfModal from './EasyConfModal';

const ProfileMenu = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const t = useTranslation();

  // const readonly = useRestriction('readonly');
  // const disableReports = useRestriction('disableReports');
  const user = useSelector((state) => state.session.user);
  // const extPermissions = useSelector((state) => state.session.permissions);
  // const socket = useSelector((state) => state.session.socket);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openCrm, setOpenCrm] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [openEasyConf, setOpenEasyConf] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);

  const [extPermissions, setExtPermissions] = useState({
    userId: -1,
    all: true,
    mainMenu: true,
    preferences: true,
    notifications: true,
    account: true,
    devices: true,
    geofence: true,
    groups: true,
    drivers: true,
    calendars: true,
    calculatedAttr: true,
    maintenance: true,
    savedCommands: true,
    server: true,
    users: true,
    editExtPermissions: true,
    logs: true,
    billing: true,
    crm: true,
    easyconf: true,
    reports: true,
    about: true,
    blockDevice: true,
    moreOptions: true,
    replay: true,
    editDevice: true,
    removeDevice: true,
    shareDevice: true,
  });

  useEffect(() => {
    if (user !== null && extPermissions.userId === -9999) {
      fetch(`${process.env.REACT_APP_MEDIA_SERVER}/get/permissions/${user.id}`, { method: 'GET' })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setExtPermissions(res);
        })
        .catch((err) => {
          console.log('Erro no get das permissões', err);
        });
    }
    // console.log('KKKKKKKKKKKKKKKKKKKK', extPermissions);
  }, [user]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    setAnchorEl(null);
    navigate(`/settings/user/${user.id}`);
  };

  const handleReports = () => {
    setAnchorEl(null);
    navigate('/reports/combined');
  };

  const handleLogs = () => {
    setAnchorEl(null);
    setOpenLogs(true);
  };

  const handleCrm = () => {
    setAnchorEl(null);
    setOpenCrm(true);
    console.log(openCrm);
  };

  const handleEasyConf = () => {
    setAnchorEl(null);
    setOpenEasyConf(true);
  };

  const handleAbout = () => {
    setAnchorEl(null);
    setOpenAbout(true);
  };

  const handleLogout = async () => {
    setAnchorEl(null);

    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken && !user.readonly) {
      window.localStorage.removeItem('notificationToken');
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }

    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    navigate('/login');
    dispatch(sessionActions.updateUser(null));
  };

  return (
    <>
      {/* <CrmModal user={user} openCrm={openCrm} setOpenCrm={setOpenCrm} /> */}
      <LogsModal isAdmin={user.administrator} openLogs={openLogs} setOpenLogs={setOpenLogs} />
      <AboutModal openAbout={openAbout} setOpenAbout={setOpenAbout} />
      <EasyConfModal openEasyConf={openEasyConf} setOpenEasyConf={setOpenEasyConf} />

      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{user.name[0]}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem onClick={handleLogs} style={{ display: extPermissions.logs ? 'block' : 'none' }}>
          <ListItemIcon>
            <BugReportIcon fontSize="small" />
          </ListItemIcon>
          Logs
        </MenuItem>

        <MenuItem onClick={handleLogs} disabled style={{ display: extPermissions.billing ? 'block' : 'none' }}>
          <ListItemIcon>
            <PaidIcon fontSize="small" />
          </ListItemIcon>
          Financeiro
        </MenuItem>

        <MenuItem onClick={handleCrm} disabled style={{ display: extPermissions.crm ? 'block' : 'none' }}>
          <ListItemIcon>
            <SupportIcon fontSize="small" />
          </ListItemIcon>
          MicroCRM
          <Typography style={{ marginLeft: '3px' }} variant="caption" color="textSecondary"><sup>Beta</sup></Typography>
        </MenuItem>

        <MenuItem onClick={handleEasyConf} style={{ display: extPermissions.easyconf ? 'block' : 'none' }}>
          <ListItemIcon>
            <AutoFixNormalIcon fontSize="small" />
          </ListItemIcon>
          EasyConf
          <Typography style={{ marginLeft: '3px' }} variant="caption" color="textSecondary"><sup>Beta</sup></Typography>
        </MenuItem>

        <MenuItem onClick={handleReports} style={{ display: extPermissions.reports ? 'block' : 'none' }}>
          <ListItemIcon>
            <SummarizeIcon fontSize="small" />
          </ListItemIcon>
          Relatórios
        </MenuItem>

        <MenuItem onClick={handleAccount} style={{ display: extPermissions.account ? 'block' : 'none' }}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          {t('settingsUser')}
        </MenuItem>

        <MenuItem onClick={handleAbout} style={{ display: extPermissions.about ? 'block' : 'none' }}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          Sobre
        </MenuItem>
        <Divider orientation="horizontal" flexItem style={{ display: extPermissions.about ? 'block' : 'none' }} />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('loginLogout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
