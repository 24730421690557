import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';

export default function CheckboxListSecondary({ filteredItems, setSelectedItems }) {
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setSelectedItems(newChecked);
  };

  return (
    <List dense sx={{ height: '100%', width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {filteredItems && filteredItems.map((object) => {
        const labelId = `checkbox-list-secondary-label-${object.id}`;
        return (
          <ListItem
            key={object.id}
            secondaryAction={(
              <Checkbox
                edge="start"
                onChange={handleToggle(object.id)}
                checked={checked.indexOf(object.id) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${object + 1}`}
                  src={`/static/images/avatar/${object + 1}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText id={labelId} primary={`${object.name} ${object.id}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
